import api from './abstract';

const authApi = {
    userInfo: function () {
        return api.get('/auth/user-info');
    },
    login: function (login, password) {
        return api.post(
            '/auth/login',
            {
                login: login,
                password: password
            }
        );
    },
    logout: function () {
        return api.post('/auth/logout');
    },
    register: function (username, email, password, repeatPassword, phone, instagram, agreement, recaptchaToken) {
        return api.post(
            '/auth/register',
            {
                username: username,
                email: email,
                password: password,
                repeatPassword: repeatPassword,
                phone: phone,
                instagram: instagram,
                agreement: agreement,
                recaptchaToken: recaptchaToken
            }
        );
    }
};

export default authApi;