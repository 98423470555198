import React from 'react';
import PropTypes from 'prop-types';
import {Navigate} from "react-router-dom";
import VideoLectureView from "./VideoLectureView";
import CardListElement from "../../base/list/CardListElement";

export default class Category extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        lectures: PropTypes.arrayOf(PropTypes.object),
        currentLectureId: PropTypes.number,
        onDeny: PropTypes.func
    };

    render() {
        let currentVideoLecture = null,
            currentLectureView = null,
            lectureList = this.props.lectures.map(item => {
                if (item.id === this.props.currentLectureId) {
                    currentVideoLecture = item;
                }

                return <CardListElement
                    key={item.id}
                    text={item.name}
                    isAllowed={item.allowed}
                    denialNotificationText="Це платний контент. Для доступу авторизуйтесь та придбайте доступ до відео-лекцій."
                    url={'/materials/history/video-lectures/' + item.id}
                />;
            });

        if (currentVideoLecture) {
            if (!currentVideoLecture.allowed) {
                return <Navigate to="/materials/history/video-lectures" />
            }
            currentLectureView = <VideoLectureView {...currentVideoLecture} />;
        }

        return (
            <div className="category">
                <h2 className="title"><span>{this.props.title}</span></h2>
                <ul className="list video-lectures-list">
                    {lectureList}
                </ul>
                {currentLectureView}
            </div>
        );
    }
}