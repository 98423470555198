import PropTypes from "prop-types";
import styles from './ModalForm.module.css';

const ModalForm = (props) => {
    let classes = props.classNames || [];
    classes.includes(styles.modalForm) || classes.push(styles.modalForm);

    return <div className={classes.join(' ')}>
        {props.children}
    </div>;
};

ModalForm.propTypes = {
    classNames: PropTypes.arrayOf(PropTypes.string)
};

export default ModalForm;