import React from 'react';
import PropTypes from 'prop-types';

export default class AnswerButtonBlock extends React.Component {
    static propTypes = {
        isTestFinished: PropTypes.bool.isRequired,
        toggleTestIsFinished: PropTypes.func.isRequired,
        goToNextQuestion: PropTypes.func,
        storeAnswer: PropTypes.func
    };

    render() {
        let {goToNextQuestion, storeAnswer} = this.props,
            nextQuestionButton,
            storeAnswerButton;
        if (typeof storeAnswer === 'function') {
            storeAnswerButton = <button className="btn btn-outline-primary" typeof="button" onClick={storeAnswer}>Відповісти</button>;
        }
        if (typeof goToNextQuestion === 'function') {
            nextQuestionButton = <button className="btn btn-outline-secondary" typeof="button" onClick={goToNextQuestion}>
                Наступне запитання
            </button>;
        }
        return (
            <div className="answer-buttons-wrapper">
                <div>{storeAnswerButton}</div>
                <div>{nextQuestionButton}</div>
                <div>
                    <button className="btn btn-outline-danger" type="button" onClick={this.props.toggleTestIsFinished}>
                        {this.props.isTestFinished ? 'Пройти тест ще раз' : 'Завершити тест'}
                    </button>
                </div>
            </div>
        );
    }
}