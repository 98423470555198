import React from 'react';
import CardListElement from "../../base/list/CardListElement";

export default class SynopsisCategory extends React.Component {
    render() {
        const { activeItemId, subject } = this.props;

        return (
            <div className="category">
                <h2 className="title"><span>{this.props.title}</span></h2>
                <ul className="list">
                    {this.props.synopsisList.map(item => {
                        let url = '/materials/' + subject + '/synopsis/';
                        if (item.allowed) {
                            url += item.id;
                        }

                        return <CardListElement
                            key={item.id}
                            isActive={item.id === activeItemId}
                            isAllowed={item.allowed}
                            url={url}
                            text={item.name}
                            denialNotificationText="Це платний контент. Для доступу авторизуйтесь та придбайте доступ до конспектів."
                        />;
                    })}
                </ul>
            </div>
        );
    }
}