import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import ModalPortal from './ModalPortal';
import styles from './Modal.module.css';

export default class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: true
        };
    }

    static propTypes = {
        wrapperClasses: PropTypes.arrayOf(PropTypes.string),
        contentClasses: PropTypes.arrayOf(PropTypes.string),
        transparent: PropTypes.bool,
        doNotCloseByOutsideClick: PropTypes.bool
    };

    static defaultProps = {
        transparent: false,
        doNotCloseByOutsideClick: false
    };

    static getDerivedStateFromProps(props, state) {
        return {opened: true};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.opened !== prevState.opened) {
            if (this.state.opened) {
                document.addEventListener('click', this.handleOutsideClick, false);
            } else {
                document.removeEventListener('click', this.handleOutsideClick, false);
            }
        }
        if (this.state.opened) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }

    handleOutsideClick = event => {
        let node = ReactDOM.findDOMNode(this);
        if (node && !node.contains(event.target)) {
            this.setState({
                opened: false
            });

            if (typeof this.props.closeHandler === 'function') {
                this.props.closeHandler(event);
            }
        }
    }

    componentDidMount() {
        if (!this.props.doNotCloseByOutsideClick) {
            document.addEventListener('click', this.handleOutsideClick, false);
        }
        if (this.state.opened) {
            document.body.style.overflow = 'hidden';
        }
    }

    componentWillUnmount() {
        if (!this.props.doNotCloseByOutsideClick) {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        document.body.style.overflow = 'unset';
    }

    render() {
        if (!this.state.opened) {
            return null;
        }

        const wrapperClasses = this.props.wrapperClasses || [];
        const contentClasses = this.props.contentClasses || [];
        contentClasses.includes(styles.modalContent) || contentClasses.push(styles.modalContent);

        if (this.props.transparent && !contentClasses.includes(styles.transparent)) {
            contentClasses.push(styles.transparent);
        }

        return(
            <ModalPortal wrapperClasses={wrapperClasses}>
                <div className={contentClasses.join(' ')}>
                    {this.props.children}
                </div>
            </ModalPortal>
        );
    }
}