import React from 'react';
import {connect} from 'react-redux';
import {updateUser} from "../../../reducers/auth";
import ModalDialog from '../../base/modalDialog/ModalDialog';
import ModalFormBody from "../../base/modalForm/ModalFormBody";
import ModalFormFooter from "../../base/modalForm/ModalFormFooter";
import profileApi from '../../../api/profile';

class Info extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editDialog: null
        };
    }

    openEditDialog = (field) => {
        this.setState({
            editDialog: field
        });
    }

    closeEditDialog = () => {
        this.setState({
            editDialog: null
        });
    }

    handleSave = () => {
        let input = document.getElementById('info-input'),
            currentValue = this.props.user[input.name];
        if (currentValue === input.value) {
            this.setState({
                editDialog: null
            });

            return false;
        }
        profileApi.saveInfo(input.name, input.value)
            .then(response => {
                if (response.data.success) {
                    let user = Object.assign({}, this.props.user);
                    user[input.name] = input.value;

                    this.props.onInfoChange(user);

                    this.setState({
                        editDialog: null
                    });
                }
            });
    }

    render() {
        let editDialog;
        if (this.state.editDialog) {
            let value = this.props.user[this.state.editDialog];
            editDialog = <ModalDialog closeHandler={this.closeEditDialog}>
                <ModalFormBody>
                    <div className="form-group">
                        <input id="info-input" className="form-control" type="text" required name={this.state.editDialog} defaultValue={value}/>
                    </div>
                </ModalFormBody>
                <ModalFormFooter>
                    <button onClick={this.handleSave} className="btn-solid-reg">Зберегти</button>
                </ModalFormFooter>
            </ModalDialog>
        }
        return(
            <>
                <tr>
                    <td>Ім&apos;я:</td>
                    <td><b>{this.props.user.username}</b></td>
                    <td>
                        <i className="fas fa-edit"
                           title="Редагувати"
                           onClick={() => {this.openEditDialog('username')}}
                        ></i>
                    </td>
                </tr>
                <tr>
                    <td>Адреса електронної пошти:</td>
                    <td><b>{this.props.user.email}</b></td>
                    <td>
                        <i className="fas fa-edit"
                           title="Редагувати"
                           onClick={() => {this.openEditDialog('email')}}
                        ></i>
                    </td>
                </tr>
                {editDialog}
            </>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
    onInfoChange: (user) => {
        dispatch(updateUser(user));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Info);