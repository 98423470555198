import React from 'react';
import PropTypes from 'prop-types';
import AnswerVariantLabel from "./AnswerVariantLabel";
import AnswerSelectionMarker from "./AnswerSelectionMarker";
import AnswerSelection from "./AnswerSelection";
import AnswerButtonBlock from "./AnswerButtonBlock";
import CheatingWarning from "./CheatingWarning";
import TextBlock from "./TextBlock";

export default class AnswerMatch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: {}
        };
    }

    static propTypes = {
        questionId: PropTypes.number.isRequired,
        storeAnswer: PropTypes.func.isRequired,
        toggleTestIsFinished: PropTypes.func.isRequired,
        isTestFinished: PropTypes.bool.isRequired,
        isOpenedTwice: PropTypes.bool,
        subject: PropTypes.string.isRequired
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.questionId !== this.props.questionId && Object.keys(this.state.answers).length > 0) {
            this.setState({answers: {}});
        }
    }

    onAnswerChange = (e) => {
        let {target} = e;

        this.setState(state => {
            state.answers[target.name] = target.value;

            return state;
        });
    };

    storeAnswer = () => {
        this.props.storeAnswer(this.props.questionId, this.state.answers);
    };

    getAnswerSelectionInput = (leftAnswer, rightAnswer) => {
        let isReadOnly = false,
            onChange,
            isChecked,
            isCorrect = false,
            isIncorrect = false,
            rowIndex = leftAnswer.index,
            index = rightAnswer.index;

        if (this.props.answer !== null) {
            isReadOnly = true;
            isChecked = typeof this.props.answer[rowIndex] !== 'undefined' && parseInt(this.props.answer[rowIndex]) === index;
            isCorrect = (!this.props.isOpenedTwice || this.props.isTestFinished) && leftAnswer.index === rightAnswer.index;
            isIncorrect = (!this.props.isOpenedTwice || this.props.isTestFinished) && isChecked && !isCorrect;
        } else {
            isChecked = this.state.answers[rowIndex] !== 'undefined' && parseInt(this.state.answers[rowIndex]) === index;
        }

        isReadOnly = isReadOnly || this.props.isTestFinished;
        if (isReadOnly) {
            onChange = (e) => {
                e.preventDefault();
                return false;
            };
        } else {
            onChange = this.onAnswerChange;
        }

        return <AnswerSelectionMarker isReadOnly={isReadOnly} isCorrect={isCorrect} isIncorrect={isIncorrect}>
            <input
                type="radio"
                name={rowIndex}
                value={index}
                onChange={onChange}
                readOnly={isReadOnly}
                checked={isChecked}
            />
        </AnswerSelectionMarker>;
    };

    render() {
        let selectionHead = [<span></span>],
            selectionBody = [],
            storeAnswerCallback;
        let leftAnswers = this.props.answers.left.filter(answer => {
            answer.body = answer.body || '';

            return answer.body !== '';
        });
        let leftAnswersCount = leftAnswers.length;
        let matchLeft = leftAnswers.map((answer, index) => {
            let label = <AnswerVariantLabel index={index} isNumeric={true} />;
            selectionBody.push([label]);

            return <li key={index}>{label} <TextBlock subject={this.props.subject}><span dangerouslySetInnerHTML={{__html: answer.body}}></span></TextBlock></li>;
        });
        let matchRight = this.props.answers.right.map((answer, index) => {
            let body = answer.body || '';
            if (body === '') {
                return null;
            }

            let label = <AnswerVariantLabel index={index} isNumeric={false} />;
            selectionHead.push(label);
            for (let i = 0; i < leftAnswersCount; ++i) {
                selectionBody[i].push(this.getAnswerSelectionInput(leftAnswers[i], answer));
            }

            return <li key={index}>{label} <TextBlock subject={this.props.subject}><span dangerouslySetInnerHTML={{__html: body}}></span></TextBlock></li>
        });

        if (this.props.answer === null && Object.keys(this.state.answers).length !== 0) {
            storeAnswerCallback = this.storeAnswer;
        }

        return (
            <>
                <div className="answer-variants-wrapper">
                    <ul className="answer-variants col-left">
                        {matchLeft}
                    </ul>
                    <ul className="answer-variants col-right">
                        {matchRight}
                    </ul>
                </div>
                <AnswerSelection
                    head={selectionHead}
                    rows={selectionBody}
                    showWarning={!!storeAnswerCallback}
                    isOpenedTwice={this.props.isOpenedTwice}
                />
                {this.props.isOpenedTwice && !this.props.isTestFinished && <CheatingWarning />}
                <AnswerButtonBlock
                    goToNextQuestion={this.props.goToNextQuestion}
                    storeAnswer={storeAnswerCallback}
                    toggleTestIsFinished={this.props.toggleTestIsFinished}
                    isTestFinished={this.props.isTestFinished}
                />
            </>
        );
    }
}