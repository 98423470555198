import React from 'react';
import {connect} from 'react-redux';
import authApi from '../../api/auth';
import {doLogout, doLogin} from "../../reducers/auth";
import {notificationsActions} from "../../reducers/notifications";
import eventService from '../../services/events';
import AuthorizedUserMenu from "./AuthorizedUserMenu";
import UnauthorizedUserMenu from "./UnauthorizedUserMenu";
import './Profile.css';

class UserMenu extends React.Component {
    checkAuthorization = () => {
        if (this.props.loggedIn) {
            authApi.userInfo()
                .then(
                    (response) => {
                        if (response.data.hasOwnProperty('loggedIn')) {
                            if (response.data.loggedIn) {
                                this.props.onInit(response.data);
                            } else {
                                this.props.onLogout();
                            }
                        }
                    }
                );
        }
    }

    componentDidMount() {
        this.checkAuthorization();
        eventService.subscribe('unauthorizedAction', 'UserMenu', this.checkAuthorization)
    }

    componentWillUnmount() {
        eventService.unsubscribe('unauthorizedAction', 'UserMenu');
    }

    render() {
        return this.props.loggedIn
            ? <AuthorizedUserMenu username={this.props.username} />
            : <UnauthorizedUserMenu />;
    }
}

let mapStateToProps = state => ({
    loggedIn: state.auth.loggedIn,
    ...state.auth.user
});

const mapDispatchToProps = dispatch => ({
    onInit: (payload) => {
        dispatch(doLogin(payload));
    },
    onLogout: () => {
        dispatch(doLogout());
        dispatch(notificationsActions.add({
            id: new Date().getTime(),
            type: 'error',
            text: `Здається, Ви зайшли в цей аккаунт в іншому браузері.
                   Використання одного аккаунту одночасно в двох браузерах/девайсах заборонено.
                   Здійснено примусовий вихід з аккаунта.`,
            dismissAfter: 60000
        }))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);