import React from 'react';
import Form from '../../base/Form';
import ModalDialog from '../../base/modalDialog/ModalDialog';
import ModalFormBody from '../../base/modalForm/ModalFormBody';
import ModalFormFooter from '../../base/modalForm/ModalFormFooter';
import profileApi from '../../../api/profile';

export default class Password extends Form {
    constructor(props) {
        super(props);

        this.state = {
            isDialogOpened: false,
            password: '',
            passwordRepeat: '',
            oldPassword: '',
            errors: {}
        };
    }

    openEditDialog = () => {
        this.setState({ isDialogOpened: true });
    };

    closeEditDialog = () => {
        this.setState({ isDialogOpened: false });
    };

    toggleEditDialog = () => {
        this.setState((state) => {
            return {
                isDialogOpened: !state.isDialogOpened
            }
        });
    }

    handleChange(event) {
        super.handleChange(event);

        let {errors} = this.state;
        if (errors.password || errors.passwordRepeat) {
            this.setState({
                errors: {}
            });
        }
    }

    validate() {
        let isValid = true,
            errors = {};
        const {password, passwordRepeat, oldPassword} = this.state;

        if (password === '') {
            errors.password = 'Необхідно ввести новий пароль.';
            isValid = false;
        }
        if (oldPassword === '') {
            errors.oldPassword = 'Необхідно ввести діючий пароль.';
            isValid = false;
        }
        if (password !== passwordRepeat) {
            errors.passwordRepeat = 'Паролі не співпадають';
            isValid = false;
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    handleSave = () => {
        if (!this.validate()) {
            return false;
        }

        const {password, passwordRepeat, oldPassword} = this.state;

        profileApi.changePassword(oldPassword, password, passwordRepeat)
            .then(response => {
                let newState = {
                    errors: response.data.errors || {}
                };

                if (response.data.success) {
                    newState.isDialogOpened = false;
                    newState.password = '';
                    newState.passwordRepeat = '';
                    newState.oldPassword = '';
                }

                this.setState(newState);
            });
    }

    render() {
        let editDialog;
        if (this.state.isDialogOpened) {
            editDialog = <ModalDialog closeHandler={this.closeEditDialog}>
                <ModalFormBody>
                    <div className="form-group">
                        <input id="old-password-input"
                               className={this.getFieldClassName('oldPassword', ['form-control'])}
                               type="password"
                               required
                               name="oldPassword"
                               defaultValue=""
                               placeholder="Старий пароль"
                               onChange={this.handleChange}
                        />
                        {this.renderError('oldPassword')}
                    </div>
                    <div className="form-group">
                        <input id="password-input"
                               className={this.getFieldClassName('password', ['form-control'])}
                               type="password"
                               required
                               name="password"
                               defaultValue=""
                               placeholder="Новий пароль"
                               onChange={this.handleChange}
                        />
                        {this.renderError('password')}
                    </div>
                    <div className="form-group">
                        <input id="password-repeat-input"
                               className={this.getFieldClassName('passwordRepeat', ['form-control'])}
                               type="password"
                               required
                               name="passwordRepeat"
                               defaultValue=""
                               placeholder="Введіть новий пароль ще раз"
                               onChange={this.handleChange}
                        />
                        {this.renderError('passwordRepeat')}
                    </div>
                </ModalFormBody>
                <ModalFormFooter>
                    <button onClick={this.handleSave} className="btn-solid-reg">Зберегти</button>
                </ModalFormFooter>
            </ModalDialog>
        }
        return(
            <>
                <tr>
                    <td>Пароль</td>
                    <td>*****</td>
                    <td>
                        <i className="fas fa-edit"
                           title="Редагувати"
                           onClick={this.openEditDialog}
                        ></i>
                    </td>
                </tr>
                {editDialog}
            </>
        );
    }
}