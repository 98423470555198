import React from 'react';

export default class GoogleMap extends React.Component {
    render() {
        let query = this.props.query || '',
            zoom = this.props.zoom,
            url = '//maps.google.com/maps?output=embed&q=' + query.replace(' ', '+')
                + '&z=' + zoom;
        return (
            <div className="map-responsive">
                <iframe src={url} allowFullScreen title={'Google map ' + query}></iframe>
            </div>
        );
    }
}