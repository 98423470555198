import React from 'react';
import {Link} from "react-router-dom";

export default class Content extends React.Component {
    render() {
        return (
            <>
                <div className="basic-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="text-container">
                                    <h2>Хочеш швидко підготуватися і успішно скласти <strong>ЗНО</strong>?</h2>
                                    <p>Пропонуємо наші курси. Під час розроки курсу викладач врахував:</p>
                                    <ul className="list-unstyled li-space-lg">
                                        <li className="media">
                                            <i className="fas fa-check"></i>
                                            <div className="media-body">
                                                Свою власну підготовку до <strong>ЗНО</strong> перед вступом до університету
                                            </div>
                                        </li>
                                        <li className="media">
                                            <i className="fas fa-check"></i>
                                            <div className="media-body">
                                                10-річний досвід підготовки учнів до <strong>ЗНО</strong>
                                            </div>
                                        </li>
                                        <li className="media">
                                            <i className="fas fa-check"></i>
                                            <div className="media-body">Психологію учнів</div>
                                        </li>
                                        <li className="media">
                                            <i className="fas fa-check"></i>
                                            <div className="media-body">Особливості пам&apos;яті людини</div>
                                        </li>
                                    </ul>
                                    <p>
                                        Курс містить: короткі конспекти, шпаргалки, таблиці, інші матеріали.
                                        <br/>
                                        Підтримка і консультація протягом року.
                                    </p>
                                    <Link className="btn-solid-reg blinking" to="/contacts">Записатись</Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-container">
                                    <img className="img-fluid" src="/images/details-1-office-worker.svg"
                                         alt="Онлайн підготовка до ЗНО" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="basic-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="image-container">
                                    <img className="img-fluid" src="/images/details-2-office-team-work.svg"
                                         alt="Групові заняття" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-container">
                                    <h2>Конспекти містять</h2>
                                    <ul className="list-unstyled li-space-lg">
                                        <li className="media">
                                            <i className="fas fa-check"></i>
                                            <div className="media-body">
                                                Тільки необхідне відповідно до <strong>Програми ЗНО 2023</strong>
                                            </div>
                                        </li>
                                        <li className="media">
                                            <i className="fas fa-check"></i>
                                            <div className="media-body">
                                                Дати, персоналії, терміни, карти, візуальні джерела.
                                            </div>
                                        </li>
                                        <li className="media">
                                            <i className="fas fa-check"></i>
                                            <div className="media-body">
                                                Чітко виділено причини та наслідки подій
                                            </div>
                                        </li>
                                        <li className="media">
                                            <i className="fas fa-check"></i>
                                            <div className="media-body">
                                                Найбільш популярні цитати з документів
                                            </div>
                                        </li>
                                        <li className="media">
                                            <i className="fas fa-check"></i>
                                            <div className="media-body">Найбільш поширені плакати</div>
                                        </li>
                                    </ul>
                                    <Link className="btn-solid-reg blinking" to="/contacts">Замовити</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}