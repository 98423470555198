import { useEffect } from 'react';
import {connect, useDispatch} from 'react-redux';
import { useLocation } from "react-router-dom";
import {navbarActions} from "../../reducers/navbar";

const NavbarToggler = ({ isToggled }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(navbarActions.close());
    }, [location, dispatch]);

    const toggle = () => {
        dispatch(navbarActions.toggle());
    }

    const classes = ['navbar-toggler'];
    if (isToggled) {
        classes.push('toggled');
    }

    return(
        <button className={classes.join(' ')} type="button" onClick={toggle}>
            <span className="navbar-toggler-awesome fas fa-bars"></span>
            <span className="navbar-toggler-awesome fas fa-times"></span>
        </button>
    );
}

const mapStateToProps = state => ({
    isToggled: state.navbar.isToggled
});

export default connect(mapStateToProps)(NavbarToggler);