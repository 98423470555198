import React from 'react';
import Header from "./Header";
import ContentCards from "./ContentCards";
import Content from "./Content";
import Testimonials from "./Testimonials";
import Team from "./Team";
import './Home.css';

export default class Home extends React.Component {
    render() {
        return(
            <main className="homepage">
                <Header />
                <ContentCards />
                <Testimonials />
                <Content />
                <Team />
            </main>
        );
    }
}