import React from 'react';
import {PRICES} from "../../constants/pricing";
import PricingCard from "./PricingCard";

export default class Pricing extends React.Component {
    render() {
        let cardClassName = PRICES.length === 4 ? 'row-4' : 'row-3';

        return (
            <div className="cards-2 pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Ми пропонуємо</h2>
                            <p className="p-heading p-large">
                                Ми підготували цінові пропозиції на будь-який бюджет,
                                щоб ви могли почати <strong>підготовку до ЗНО</strong> вже зараз.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 tariff-list">
                            {PRICES.map((price, index) => {
                                return <PricingCard key={index} additionalClassName={cardClassName} {...price} />;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}