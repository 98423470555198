import classes from "./ErrorMessage.module.css";

const ErrorMessage = ({children}) => {
    return (
        <div className={classes.wrapper}>
            <div className={classes.error}>
                {children}
            </div>
        </div>
    );
};

export default ErrorMessage;