import React from "react";
import styles from "./BackToTop.module.css";

export default class BackToTop extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShown: this.shouldBeShown(),
            isTransparent: !this.shouldBeShown()
        };
    }

    shouldBeShown = () => {
        const doc = document.documentElement,
            position = (window.scrollY || doc.scrollTop)  - (doc.clientTop || 0);

        return position > 700;
    };

    isShown = () => {
        return this.state.isShown;
    }

    show = async () => {
        this.setState({
            isShown: true,
            isTransparent: true
        });

        await new Promise((r) => setTimeout(r, 50));

        this.setState({
            isTransparent: false
        });
    };

    hide = async () => {
        this.setState({
            isTransparent: true
        });

        await new Promise((r) => setTimeout(r, 500));

        this.setState({
            isShown: false
        });
    };

    onScroll = () => {
        const shouldBeShown = this.shouldBeShown();
        if (this.isShown() && !shouldBeShown) {
            this.hide();
        } else if (!this.isShown() && shouldBeShown) {
            this.show();
        }
    };

    scrollToTop = () => {
        window.scrollTo ? window.scrollTo({ top: 0, behavior: 'smooth' }) : document.documentElement.scrollTop = 0;
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
    }

    render() {
        const classNames = [styles.backToTop];
        this.isShown() && classNames.push(styles.visible);
        this.state.isTransparent && classNames.push(styles.transparent);

        return (
            <span onClick={this.scrollToTop} className={classNames.join(' ')}>Back to Top</span>
        );
    }
}