import React from 'react';
import Modal from "./modal/Modal";
import Preloader from "./preloader/Preloader";

export default class ModalPreloader extends React.Component {
    render() {
        return <Modal doNotCloseByOutsideClick={true} transparent={true}>
            <Preloader/>
        </Modal>;
    }
}