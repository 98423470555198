import React from 'react';
import PropTypes from 'prop-types';

export default class SocialIcon extends React.Component {
    render() {
        const iconClasses = [
            'fa-stack-1x',
            this.props.iconClass,
            'fa-' + this.props.name
        ];
        return (
            <span className="fa-stack">
                <a href={this.props.url} target="_blank" rel="noopener noreferrer">
                    <i className={'fas fa-circle fa-stack-2x ' + this.props.name}></i>
                    <i className={iconClasses.join(' ')}></i>
                </a>
            </span>
        );
    }
}

SocialIcon.propTypes = {
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};

SocialIcon.defaultProps = {
    iconClass: 'fab'
};