import React from 'react';
import {connect} from "react-redux";
import {notificationsActions} from "../../reducers/notifications";
import PropTypes from "prop-types";
import styles from './Notifications.module.css';

const mapDispatchToProps = dispatch => ({
    onHide: payload => {
        dispatch(notificationsActions.remove(payload));
    }
});

class NotificationMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHiding: false
        };
    }

    static propTypes = {
        text: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
        isTransparent: PropTypes.bool,
        dismissAfter: PropTypes.number,
        onHide: PropTypes.func
    };

    static defaultProps = {
        type: 'success',
        isTransparent: false
    };

    hide = async () => {
        this.setState({
            isHiding: true
        })

        await new Promise(r => setTimeout(r, 1000));

        let {onHide, ...rest} = this.props;

        onHide(rest);
    };

    componentDidMount() {
        if (this.props.dismissAfter) {
            this.timeout = setTimeout(this.hide, this.props.dismissAfter);
        }
    };

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    render() {
        let classes = [styles.notificationMessage];
        switch (this.props.type) {
            case 'info':
                classes.push(styles.info);
                break;
            case 'warning':
                classes.push(styles.warning);
                break;
            case 'error':
                classes.push(styles.error);
                break;
            default:
                classes.push(styles.success);
                break;
        }

        if (this.props.isTransparent || this.state.isHiding) {
            classes.push(styles.transparent);
        }

        return (
            <div className={classes.join(' ')}>
                <div onClick={this.hide} className={styles.closeButton} title="Закрити">&times;</div>
                {this.props.text}
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(NotificationMessage);