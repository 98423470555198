import { createSlice } from "@reduxjs/toolkit";

const getInitialState = () => {
    let user = localStorage.getItem('user');
    let token = localStorage.getItem('token');

    try {
        user = JSON.parse(user);
    } catch (e) {
        user = {}
    }

    user = user || {};

    return {
        loggedIn: !!user.username && !!token,
        token: token,
        user: user
    };
};

const authSlice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        login(state, action) {
            state.loggedIn = action.payload.loggedIn;
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        logout(state) {
            state.loggedIn = false;
            state.user = {};
            state.token = undefined;
        },
        updateToken(state, action) {
            state.token = action.payload;
        },
        updateUser(state, action) {
            state.user = action.payload;
        }
    }
});

export const doLogout = () => {
    return (dispatch) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        dispatch(authSlice.actions.logout());
    };
};

export const doLogin = (data) => {
    return (dispatch) => {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));

        dispatch(authSlice.actions.login(data));
    };
};

export const updateUser = (userData) => {
    return (dispatch) => {
        localStorage.setItem('user', JSON.stringify(userData));

        dispatch(authSlice.actions.updateUser(userData));
    };
};

export const updateToken = (token) => {
    return (dispatch) => {
        localStorage.setItem('token', token);

        dispatch(authSlice.actions.updateToken(token))
    };
};

export const authActions = authSlice.actions;
export default authSlice.reducer;