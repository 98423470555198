import React from 'react';
import DaysLeft from "./DaysLeft";
import Pricing from "./Pricing";

export default class ContentCards extends React.Component {
    render() {
        return (
            <>
            <div className="cards-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Часу не так багато як може здатися на перший погляд</h2>
                            <p className="p-large">
                                Якщо ви бажаєте мати хороший результат, то з власного досвіду можу дати пораду не
                                втрачати час,
                                не відкладати &quot;на потім&quot; і розпочати готуватись вже зараз.
                            </p>
                            <DaysLeft />
                        </div>
                    </div>
                </div>
            </div>
                <Pricing />
            <div className="cards-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 cards-wrapper">
                            <div className="card card-responsive">
                                <div>
                                    <div className="card-stats-note">більше</div>
                                    <div className="card-stats">700</div>
                                    <div className="card-body">
                                        <p className="cb-highlighted">
                                            учнів підготувала особисто
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-responsive">
                                <div>
                                    <div className="card-stats-note">більше</div>
                                    <div className="card-stats">50000</div>
                                    <div className="card-body">
                                        <p className="cb-highlighted">
                                            осіб, в тому числі вчителі, користуються моїми конспектами
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-responsive">
                                <div>
                                    <div className="card-stats-note">більше</div>
                                    <div className="card-stats">180</div>
                                    <div className="card-body">
                                        <p className="cb-highlighted">
                                            середній бал учнів
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <img className="card-image" src="/images/services-icon-1.svg"
                                     alt="Заняття один на один" />
                                <div className="card-body">
                                    <h4 className="card-title">Особисті заняття</h4>
                                    <p>
                                        Врахування індивідуальних особливостей абітурієнта.
                                        Постійний контроль з боку викладача.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <img className="card-image" src="/images/online-education.svg"
                                     alt="Онлайн заняття" />
                                <div className="card-body">
                                    <h4 className="card-title">Онлайн заняття</h4>
                                    <p>
                                        Можливість планувати заняття без прив&apos;язки до локації.
                                        Підходить для самоорганізованих людей.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <img className="card-image" src="/images/online-synopsis.svg"
                                     alt="Онлайн конспекти" />
                                <div className="card-body">
                                    <h4 className="card-title">Завжди під рукою</h4>
                                    <p>
                                        Можливість займатись в будь-який час в будьякому місці.
                                        Ваші конспекти завжди в вашому гаждеті.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}