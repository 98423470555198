import api from './abstract';

const znoTestsApi = {
    getCategoryList(subject) {
        return api.get('/' + subject + '/zno-test/list');
    },

    getTestQuestionList(subject, testId) {
        return api.get('/' + subject + '/zno-test/question-list/' + testId);
    },

    saveResults(testId, answers, timeSpent) {
        return api.post(
            '/zno-test/save-results',
            {
                testId: testId,
                answers: answers,
                timeSpent: timeSpent
            }
        );
    }
};

export default znoTestsApi;