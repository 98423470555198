import React from 'react';
import PropTypes from 'prop-types';
import AnswerVariantLabel from "./AnswerVariantLabel";
import AnswerSelectionMarker from "./AnswerSelectionMarker";
import AnswerSelection from "./AnswerSelection";
import AnswerButtonBlock from "./AnswerButtonBlock";
import CheatingWarning from "./CheatingWarning";
import TextBlock from "./TextBlock";

export default class AnswerDefault extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            answer: {}
        };
    }

    static propTypes = {
        questionId: PropTypes.number.isRequired,
        storeAnswer: PropTypes.func.isRequired,
        toggleTestIsFinished: PropTypes.func.isRequired,
        isTestFinished: PropTypes.bool.isRequired,
        isOpenedTwice: PropTypes.bool.isRequired,
        subject: PropTypes.string.isRequired
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.questionId !== this.props.questionId && Object.keys(this.state.answer).length > 0) {
            this.setState({answer: {}});
        }
    }

    setAnswer = (value) => {
        let answer = {};
        answer[value] = true;
        this.setState({answer: answer});
    };

    storeAnswer = () => {
        this.props.storeAnswer(this.props.questionId, this.state.answer);
    };

    getSelectionInput = (answer) => {
        let isChecked = false,
            isReadonly = false,
            onChange,
            isCorrect = false,
            isIncorrect = false,
            {index} = answer;
        if (this.props.answer !== null) {
            isChecked = this.props.answer[index] === true;
            isReadonly = true;
            isCorrect = (!this.props.isOpenedTwice || this.props.isTestFinished) && !!answer.isCorrect;
            isIncorrect = (!this.props.isOpenedTwice || this.props.isTestFinished) && !isCorrect && isChecked;
        } else {
            if (this.state.answer !== null) {
                isChecked = this.state.answer[index] === true;
            }
        }
        isReadonly = isReadonly || this.props.isTestFinished;
        if (isReadonly) {
            onChange = (e) => {
                e.preventDefault();
                return false;
            };
        } else {
            onChange = () => {
                this.setAnswer(index);
            }
        }

        return <AnswerSelectionMarker isReadOnly={isReadonly} isCorrect={isCorrect} isIncorrect={isIncorrect}>
            <input
                type="radio"
                name="input-name"
                value={index}
                onChange={onChange}
                readOnly={isReadonly}
                checked={isChecked} />
        </AnswerSelectionMarker>;
    };

    render() {
        let selectionHead = [],
            selectionBody = [],
            storeAnswerCallback;
        let variants = this.props.answers.map((answer, index) => {
            let label = <AnswerVariantLabel index={index} isNumeric={false} />;
            selectionHead.push(label);

            selectionBody.push(this.getSelectionInput(answer));

            return <li key={index}>{label} <TextBlock subject={this.props.subject}><span dangerouslySetInnerHTML={{__html: answer.body}}></span></TextBlock></li>;
        });

        if (Object.keys(this.state.answer).length > 0 && this.props.answer === null) {
            storeAnswerCallback = this.storeAnswer;
        }

        return (
            <>
                <ul className="answer-variants">
                    {variants}
                </ul>
                <AnswerSelection
                    head={selectionHead}
                    rows={[selectionBody]}
                    isSeveral={false}
                    showWarning={!!storeAnswerCallback}
                    isOpenedTwice={this.props.isOpenedTwice}
                />
                {this.props.isOpenedTwice && !this.props.isTestFinished && <CheatingWarning />}
                <AnswerButtonBlock
                    goToNextQuestion={this.props.goToNextQuestion}
                    storeAnswer={storeAnswerCallback}
                    toggleTestIsFinished={this.props.toggleTestIsFinished}
                    isTestFinished={this.props.isTestFinished}
                />
            </>
        );
    }
}