import api from './abstract';

const contactApi = {
    submitCourseRequest: function (reCaptchaToken, fullName, phone, email) {
        return api.post(
            '/contact/course-request',
            {
                reCaptchaToken: reCaptchaToken,
                fullName: fullName,
                phone: phone,
                email: email
            }
        );
    }
};

export default contactApi;