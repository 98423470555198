import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, useParams} from 'react-router-dom';
import {fetchSynopses} from "../../../reducers/synopses";
import SynopsisList from "./SynopsisList";
import SynopsisView from "./SynopsisView";
import PageTitle from "../../base/PageTitle";
import ModalPreloader from "../../base/ModalPreloader";
import './Synopsis.css';
import ErrorMessage from "../../base/errorMessage/ErrorMessage";

const Synopsis = ({subject}) => {
    const { id } = useParams();
    const loggedIn = useSelector(state => state.auth.loggedIn);
    const {isLoaded, error, synopsisList} = useSelector(state => state.synopses[subject]);
    const dispatch = useDispatch();

    const pageTitle = useMemo(() => {
        let title = 'Конспекти для підготовки до ЗНО';
        switch (subject) {
            case 'history':
                title += ' з історії України';
                break;
            case 'math':
                title += ' з математики';
                break;
            case 'ukrmova':
                title += ' з української мови';
                break;
            default:
                break;
        }

        return title;
    }, [subject]);

    useEffect(() => {
        dispatch(fetchSynopses(subject));
    }, [loggedIn, subject, dispatch]);

    if (error) {
        return <ErrorMessage>
            Не вдалося завантажити список конспектів, спробуйте ще раз. Якщо помилка повториться, повідомте викладача.
        </ErrorMessage>;
    }

    if (!isLoaded) {
        return <ModalPreloader />;
    }

    let synopsisView;

    if (id) {
        synopsisViewLoop: for (let categoryIndex in synopsisList) {
            let category = synopsisList[categoryIndex];
            for (let synopsisIndex in category.synopsisList) {
                let synopsis = category.synopsisList[synopsisIndex];
                if (synopsis.id === id) {
                    if (synopsis.allowed) {
                        synopsisView = <SynopsisView id={id} subject={subject}/>
                    }
                    break synopsisViewLoop;
                }
            }
        }

        if (!synopsisView) {
            return <Navigate to={"/materials/" + subject + "/synopsis"}/>
        }
    }

    return <div className="synopsis-page">
        <PageTitle pageTitle={pageTitle}/>
        {isLoaded && <SynopsisList list={synopsisList} activeItemId={id} subject={subject} />}
        {synopsisView}
    </div>;
}

export default Synopsis;