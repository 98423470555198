import React from 'react';
import TeamMember from "./TeamMember";
import {CONTACT} from "../../constants/settings";

export default class Team extends React.Component {
    render() {
        const teamMembersData = [
            {
                name: 'Олеся Анатоліївна',
                job: 'Історія України',
                photoSrc: '/images/teacher1.jpg',
                socialLinks: [
                    {
                        name: 'facebook-f',
                        url: CONTACT.facebook,
                    },
                    {
                        name: 'instagram',
                        url: CONTACT.instagram,
                    }
                ],
            }/*,
            {
                name: 'Сузанна Мурадян',
                job: 'Українська мова та література',
                photoSrc: '/images/teacher2.jpg',
                socialLinks: [
                    {
                        name: 'instagram',
                        url: 'https://www.instagram.com/movo.znavets/',
                    },
                    {
                        name: 'fa-globe',
                        url: 'https://www.movoznavets.ml/',
                    }
                ],
            }*/
        ];

        return (
            <div className="basic-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Наші викладачі</h2>
                            <p className="p-heading p-large">
                                Наші викладачі мають великий успішний доствід <strong>піготовки учнів до ЗНО</strong>.
                                Розробили власні конспекти і методику для успішного складання ЗНО на високий бал
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            {teamMembersData.map(item => <TeamMember key={item.name} {...item} />)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}