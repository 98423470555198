import React from "react";
import PropTypes from "prop-types";

export default class ListElementWrapper extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        classNames: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        onClick: () => {},
        classNames: []
    };

    render() {
        const { classNames } = this.props;
        classNames.includes('list-element-wrapper') || classNames.push('list-element-wrapper');
        return (
            <div className={classNames.join(' ')} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        );
    }
}