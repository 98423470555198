import React from 'react';
import Form from '../base/Form';
import { connect } from 'react-redux';
import authApi from '../../api/auth';
import {doLogin} from "../../reducers/auth";
import {GOOGLE_RECAPTCHA_KEY} from "../../constants/settings";
import Preloader from "../base/preloader/Preloader";
import ModalForm from "../base/modalForm/ModalForm";

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    onRegister: (payload) => {
        dispatch(doLogin(payload));
    }
});

class Registration extends Form {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            password: '',
            repeatPassword: '',
            phone: '',
            instagram: '',
            agreement: false,
            errors: {},
            isInProcess: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    toggleAgreement = () => {
        this.setState(state => {
            return {
                agreement: !state.agreement
            };
        });
    };

    handlePhoneChange = (e) => {
        let phone = Number(e.target.value);
        if (e.target.value.replace('+', '').length > 12) {
            e.preventDefault();
            return false;
        }
        if (phone.toString() !== 'NaN' || e.target.value === '+') {
            this.handleChange(e);
        }
    };

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.isInProcess) {
            return false;
        }
        this.setState({
            isInProcess: true,
            errors: {}
        });
        let {username, email, password, repeatPassword, phone, instagram, agreement} = this.state;
        let self = this;
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(GOOGLE_RECAPTCHA_KEY, {action: 'submit'})
                .then(
                    function (token) {
                        authApi.register(
                            username,
                            email,
                            password,
                            repeatPassword,
                            phone,
                            instagram,
                            agreement,
                            token
                        ).then(response => {
                                self.setState({
                                    isInProcess: false
                                });
                                let data = response.data;

                                if (data.success && data.auth.loggedIn) {
                                    self.props.onRegister(data.auth);
                                } else if (data.errors) {
                                    self.setState({
                                        errors: data.errors
                                    });
                                }
                            },
                            () => self.setState({isInProcess: false})
                        );
                    },
                    function () {
                        self.setState({
                            errors: {
                                isInProcess: false,
                                recaptchaToken: 'Ваша поведінка схожа на бота.'
                            }
                        });
                    });
        });

        return false;
    }

    render() {
        if (this.props.auth.loggedIn) {
            return null;
        }

        return(
            <ModalForm classNames={['registration-form']}>
                <h2>Реєстрація</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        {this.renderError('recaptchaToken')}
                    </div>
                    <div className="form-field-group">
                        <div className="form-group">
                            <label htmlFor="username">Прізвище та ім'я <span className="required-mark">*</span></label>
                            <input id="username"
                                   type="text"
                                   name="username"
                                   className={this.getFieldClassName('username', ['form-control'])}
                                   value={this.state.username}
                                   onChange={this.handleChange}
                            />
                            {this.renderError('username')}
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Адреса електронної пошти <span className="required-mark">*</span></label>
                            <input id="email"
                                   type="email"
                                   name="email"
                                   className={this.getFieldClassName('email', ['form-control'])}
                                   value={this.state.email}
                                   onChange={this.handleChange}
                            />
                            {this.renderError('email')}
                        </div>
                    </div>
                    <div className="form-field-group">
                        <div className="form-group">
                            <label htmlFor="password">Пароль <span className="required-mark">*</span></label>
                            <input id="password"
                                   type="password"
                                   name="password"
                                   className={this.getFieldClassName('password', ['form-control'])}
                                   value={this.state.password}
                                   onChange={this.handleChange}
                            />
                            {this.renderError('password')}
                        </div>
                        <div className="form-group">
                            <label htmlFor="repeat-password">Повторіть пароль <span className="required-mark">*</span></label>
                            <input id="repeat-password"
                                   type="password"
                                   name="repeatPassword"
                                   className={this.getFieldClassName('repeatPassword', ['form-control'])}
                                   value={this.state.repeatPassword}
                                   onChange={this.handleChange}
                            />
                            {this.renderError('repeatPassword')}
                        </div>
                    </div>
                    <div className="form-field-group">
                        <div className="form-group">
                            <label htmlFor="instagram">Instagram</label>
                            <input id="instagram"
                                   type="text"
                                   name="instagram"
                                   className={this.getFieldClassName('instagram', ['form-control'])}
                                   value={this.state.instagram}
                                   onChange={this.handleChange}
                            />
                            {this.renderError('instagram')}
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Телефон <span className="required-mark">*</span></label>
                            <input id="phone"
                                   type="text"
                                   name="phone"
                                   className={this.getFieldClassName('phone', ['form-control'])}
                                   value={this.state.phone}
                                   onChange={this.handlePhoneChange}
                                   placeholder="+380001234567"
                            />
                            {this.renderError('phone')}
                            <div className="form-control-tip">
                                Вкажіть телефон, на який зареєстровані в Telegram.
                                Для того, щоб додати Вас в закриту групу,
                                де можете отримувати консультації від викладача протягом року
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="agreement-checkbox" className="control-label">
                            <input id="agreement-checkbox" type="checkbox" checked={this.state.agreement} onChange={this.toggleAgreement} />
                            Зобов&apos;язуюсь не розповсюджувати і не передавати третім особам матеріали,
                            які використовую в особистому кабінеті на сайті {window.location.host}
                        </label>
                        {this.renderError('agreement')}
                    </div>
                    <button type="submit" className="form-control-submit-button" disabled={this.state.isInProcess}>
                        {this.state.isInProcess ? <Preloader color="secondary" /> : 'Зареєструватись'}
                    </button>
                </form>
            </ModalForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);