import React from 'react';
import profileApi from "../../../api/profile";
import ModalPreloader from "../../base/ModalPreloader";
import TestPassageHistory from "./TestPassageHistory";
import './Cabinet.css';

export default class Cabinet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            testPassageHistory: {}
        };
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        if (this.state.isLoaded === true) {
            this.setState({isLoaded: false});
        }

        profileApi.getTestPassageHistory().then(response => {
            this.setState({
                isLoaded: true,
                testPassageHistory: response.data
            });
        });
    };

    render() {
        if (!this.state.isLoaded) {
            return <ModalPreloader />;
        }

        return (
            <div className="cabinet container">
                <TestPassageHistory history={this.state.testPassageHistory} />
            </div>
        );
    }
}