import PropTypes from "prop-types";
import Warning from "../../../base/Warning";

const PressingButtonReminder = ({isOpenedTwice}) => {
    let text = 'Тисніть "Відповісти"';
    if (!isOpenedTwice) {
        text += ', щоб дізнатись правильну відповідь';
    }

    return <Warning>{text}</Warning>;
}

PressingButtonReminder.propTypes = {
    isOpenedTwice: PropTypes.bool
};

PressingButtonReminder.defaultProps = {
    isOpenedTwice: false
};

export default PressingButtonReminder;