import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

export default class GA extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired
    };

    componentDidMount() {
        ReactGA.initialize(this.props.id);
        ReactGA.set({ page: window.location.pathname }); // Update the user's current page
        ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.location !== this.props.location) {
            ReactGA.set({ page: window.location.pathname }); // Update the user's current page
            ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
        }
    }

    render() {
        return undefined;
    }
};