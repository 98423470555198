import React from 'react';

export default class Copyright extends React.Component {
    render() {
        return (
            <div className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="p-small">Copyright © {new Date().getFullYear()} - Всі права захищено</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}