import React from "react";
import PropTypes from "prop-types";
import {Accordion} from "react-bootstrap";
import TestPassageHistoryCard from "./TestPassageHistoryCard";

export default class TestPassageHistory extends React.Component {
    static propTypes = {
        history: PropTypes.arrayOf(PropTypes.object)
    };

    render() {
        if (this.props.history.length < 1) {
            return (
                <div className="centered-message-wrapper">
                    <div className="centered-message text-center">
                        <div className="centered-message-title">Ви ще не пройшли жодного тесту</div>
                        <div className="centered-message-body">Результати тестування з&apos;являться щойно ви завершите свій перший тест</div>
                    </div>
                </div>
            );
        }

        return (
            <Accordion>
                {this.props.history.map(item => {
                    return <TestPassageHistoryCard {...item} id={item.testId} key={item.testId}/>;
                })}
            </Accordion>
        );
    }
}