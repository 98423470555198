import React from 'react';
import PropTypes from 'prop-types';

export default class PricingCard extends React.Component {
    static propTypes = {
        additionalClassName: PropTypes.string,
        isFeatured: PropTypes.bool,
        isHighlighted: PropTypes.bool,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        price: PropTypes.number.isRequired,
        oldPrice: PropTypes.number,
        currency: PropTypes.string,
        priceNote: PropTypes.string,
        priceStartsWith: PropTypes.bool,
        benefits: PropTypes.arrayOf(PropTypes.string).isRequired
    };

    static defaultProps = {
        additionalClassName: 'row-3',
        isFeatured: false,
        isHighlighted: false,
        priceStartsWith: false,
        currency: 'грн'
    };

    render() {
        let cardClassNames = ['card ' + this.props.additionalClassName];
        if (this.props.isHighlighted) {
            cardClassNames.push('highlighted');
        }

        return (
            <div className={cardClassNames.join(' ')}>
                {this.props.isFeatured && <div className="label"><p className="best-value">Вибір №1</p></div>}
                <div className="card-body">
                    <div className="card-heading-wrapper">
                        <div className="card-heading">
                            <div className="card-title">{this.props.title}</div>
                            <div className="card-subtitle">{this.props.subtitle}</div>
                        </div>
                    </div>
                    <hr className="cell-divide-hr" />
                    <div className="price">
                        <div>
                            {this.props.priceStartsWith && <span className="prefix">від</span>}
                            <span className="value">{this.props.price}</span>
                            <span className="currency">{this.props.currency}</span>
                        </div>
                        {this.props.oldPrice && <div><span className="value-old">{this.props.oldPrice + ' ' + this.props.currency}</span></div>}
                        {this.props.priceNote && <div><span className="price-note">{this.props.priceNote}</span></div>}
                    </div>
                    <hr className="cell-divide-hr" />
                    <ul className="list-unstyled li-space-lg">
                        {this.props.benefits.map((benefit, index) => {
                            return <li key={index} className="media">
                                <i className="fas fa-check"></i>
                                <div className="media-body">{benefit}</div>
                            </li>
                        })}
                    </ul>
                    <div className="button-wrapper">
                        <a className="btn-solid-reg blinking" href="/contacts">Замовити</a>
                    </div>
                </div>
            </div>
        );
    }
}