import React from 'react';
import Form from '../base/Form';
import {connect} from 'react-redux';
import authApi from '../../api/auth';
import Preloader from "../base/preloader/Preloader";
import ModalForm from "../base/modalForm/ModalForm";
import { doLogin } from "../../reducers/auth";

let mapStateToProps = state => ({
    auth: state.auth
});

let mapDispatchToProps = dispatch => ({
    onLogin: (payload) => {
        dispatch(payload);
    }
});

class Login extends Form {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            login: '',
            password: '',
            errors: {},
            inProgress: false
        };
    }

    handleSubmit(event) {
        event.preventDefault();

        const {login, password} = this.state;

        this.setState({inProgress: true});
        authApi.login(login, password)
            .then(
                response => {
                    let data = response.data;
                    if (data.success) {
                        this.props.onLogin(doLogin(data.auth));
                    } else {
                        this.setState({
                            errors: data.errors,
                            inProgress: false
                        });
                    }
                },
                () => this.setState({inProgress: false})
            );

        return false;
    }

    render() {
        if (this.props.auth.loggedIn) {
            return null;
        }

        return (
            <ModalForm classNames={['login-form']}>
                <h2>Вхід</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Адреса електронної пошти</label>
                        <input
                            id="login"
                            name="login"
                            autoComplete="login"
                            autoFocus
                            value={this.state.login}
                            onChange={this.handleChange}
                            className={this.getFieldClassName('login', ['form-control'])}
                        />
                        {this.renderError('login')}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Пароль</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            className={this.getFieldClassName('password', ['form-control'])}
                        />
                        {this.renderError('password')}
                    </div>
                    <button type="submit" className="form-control-submit-button" disabled={this.state.inProgress}>
                        {this.state.inProgress ? <Preloader color="secondary" /> : 'Увійти'}
                    </button>
                </form>
            </ModalForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);