import {useCallback} from 'react';
import {connect} from "react-redux";
import {notificationsActions} from "../../../reducers/notifications";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import TruncatedTextBlock from "../TruncatedTextBlock";
import styles from "./List.module.css";

const mapDispatchToProps = dispatch => ({
    onDeny: payload => {
        dispatch(notificationsActions.add(payload))
    }
});

const CardListElement = props => {
    const { isAllowed, isActive, text, url, denialNotificationText, onDeny } = props;
    const onClick = props.onClick || null;
    const classNames = props.classNames || [];
    const clickHandler = useCallback((e) => {
        if (isAllowed) {
            onClick && onClick(e);
        } else {
            e.preventDefault();
            onDeny({
                id: new Date().getTime(),
                type: 'error',
                text: denialNotificationText,
                dismissAfter: 5000
            });

            return false;
        }
    }, [onClick, onDeny, denialNotificationText, isAllowed]);

    classNames.includes(styles.listElement) || classNames.push(styles.listElement);

    if (!isAllowed) {
        classNames.push(styles.denied);
    } else if (isActive) {
        classNames.push(styles.active);
    } else {
        classNames.push(styles.allowed);
    }

    text.includes('Пробне тестування') && !classNames.includes(styles.highlighted) && classNames.push(styles.highlighted);

    return (
        <li className={classNames.join(' ')}>
            <Link to={url} onClick={clickHandler}>
                <div className={styles.cardListElementWrapper}>
                    <TruncatedTextBlock text={text} lines={4} />
                </div>
            </Link>
        </li>
    );
};

CardListElement.propTypes = {
    isAllowed: PropTypes.bool,
    isActive: PropTypes.bool,
    url: PropTypes.string,
    onClick: PropTypes.func,
    classNames: PropTypes.arrayOf(PropTypes.string),
    text: PropTypes.string.isRequired,
    denialNotificationText: PropTypes.string,
    onDeny: PropTypes.func
};

CardListElement.defaultProps = {
    url: '',
    denialNotificationText: 'Це платний контент. Для доступу авторизуйтесь та придбайте до нього доступ.'
};

export default connect(null, mapDispatchToProps)(CardListElement);