import React from "react";
import CardListElement from "../../base/list/CardListElement";

export default class Category extends React.Component {
    render() {
        return(
            <div className="category">
                <h2 className="title"><span>{this.props.title}</span></h2>
                <ul className="list zno-test-list">
                    {this.props.testList.map(test => {
                        return <CardListElement
                            key={test.id}
                            text={test.title}
                            url={'/materials/' + this.props.subject + '/zno-tests/test/' + test.id}
                            isAllowed={test.isAllowed}
                            denialNotificationText="Це платний контент. Для доступу авторизуйтесь та придбайте доступ до тестів."
                        />;
                    })}
                </ul>
            </div>
        );
    }
}