import React from 'react';
import PropTypes from 'prop-types';

export default class AnswerSelectionMarker extends React.Component {
    static propTypes = {
        isReadOnly: PropTypes.bool,
        isCorrect: PropTypes.bool,
        isIncorrect: PropTypes.bool
    };

    static defaultProps = {
        isReadOnly: false,
        isCorrect: false,
        isIncorrect: false
    };

    render() {
        let className = 'answer-selection';
        if (this.props.isReadOnly) {
            className += ' is-readonly';
        }
        if (this.props.isCorrect) {
            className += ' is-correct';
        }
        if (this.props.isIncorrect) {
            className += ' is-incorrect';
        }

        return (
            <label className={className}>
                {this.props.children}
                <span></span>
            </label>
        );
    }
}