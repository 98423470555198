import React from 'react';
import PropTypes from 'prop-types';
import {SECONDS_FOR_QUESTION} from "../../../../constants/settings";
import TestResultRatingScore from "./TestResultRatingScore";
import TestResultTimestamp from "./TestResultTimestamp";
import {
    TYPE_DEFAULT,
    TYPE_SEVERAL,
    TYPE_SEQUENCE,
    TYPE_MATCH,
    TYPE_OPEN_SHORT_TYPE_1,
    TYPE_OPEN_SHORT_STRUCTURED,
    TYPE_OPEN
} from "../../../../constants/question-types";
import {SUBJECT_MATH, SUBJECT_HISTORY} from "../../../../constants/subject";

export default class TestResult extends React.Component {
    static propTypes = {
        questionList: PropTypes.arrayOf(PropTypes.object).isRequired,
        answers: PropTypes.object.isRequired,
        finishTime: PropTypes.object.isRequired,
        startTime: PropTypes.object.isRequired,
        testTitle: PropTypes.string.isRequired,
        isCheating: PropTypes.bool.isRequired,
        subject: PropTypes.string.isRequired
    };

    render() {
        let correctAnswersCount = 0,
            score = 0,
            maxScore = 0,
            questionsCount = this.props.questionList.length;
        for (let q = 0; q < questionsCount; ++q) {
            let question = this.props.questionList[q],
                answer = this.props.answers[question.id],
                isCorrect = false,
                questionScore = 0;
            switch (question.type) {
                case TYPE_DEFAULT:
                    maxScore += 1;
                    break;
                case TYPE_SEVERAL:
                case TYPE_SEQUENCE:
                    maxScore += 3;
                    break;
                case TYPE_MATCH:
                    if (this.props.subject === SUBJECT_HISTORY) {
                        maxScore += 4;
                    } else if (this.props.subject === SUBJECT_MATH) {
                        maxScore += 3;
                    }
                    break;
                case TYPE_OPEN_SHORT_TYPE_1:
                case TYPE_OPEN_SHORT_STRUCTURED:
                    maxScore += 2;
                    break;
                case TYPE_OPEN:
                    for (let i = 0, length = question.answers.length; i < length; ++i) {
                        let currentAnswer = question.answers[i];
                        maxScore += parseInt(currentAnswer.score);
                    }
                    break;
                default:
                    break;
            }
            if (answer) {
                let {answers} = question,
                    answerKeys = Object.keys(answer);
                switch (question.type) {
                    case TYPE_DEFAULT:// Default
                    case TYPE_SEVERAL:// Several
                        for (let i = 0, answersLength = answers.length; i < answersLength; ++i) {
                            let answerVariant = answers[i];
                            let isAnswerCorrect = !!answerVariant.isCorrect && answer[answerVariant.index];
                            if (isAnswerCorrect) {
                                ++questionScore;
                            }
                        }
                        if (question.type === TYPE_DEFAULT) {
                            isCorrect = questionScore === 1;
                        } else {
                            isCorrect = questionScore === 3;
                        }
                        break;
                    case TYPE_SEQUENCE:// Sequence
                        let minAnswerKey = Math.min.apply(null, answerKeys);
                        let maxAnswerKey = Math.max.apply(null, answerKeys);
                        if (Number(answer[minAnswerKey]) === Number(minAnswerKey)) {
                            questionScore++;
                        }
                        if (Number(answer[maxAnswerKey]) === Number(maxAnswerKey)) {
                            questionScore++;
                        }
                        if (questionScore === 2) {
                            for (let j = 0, keysLength = answerKeys.length; j < keysLength; ++j) {
                                isCorrect = Number(answer[j]) === Number(j);
                                if (!isCorrect) {
                                    break;
                                }
                            }
                        }
                        if (isCorrect) {
                            questionScore = 3;
                        }
                        break;
                    case TYPE_MATCH:// Match
                        for (let i = 0, length = answerKeys.length; i < length; ++i) {
                            let currentAnswerKey = answerKeys[i],
                                currentAnswerValue = answer[currentAnswerKey];
                            if (Number(currentAnswerKey) === Number(currentAnswerValue)) {
                                questionScore++;
                            }
                        }
                        if ((this.props.subject === SUBJECT_HISTORY && questionScore === 4)
                            || (this.props.subject === SUBJECT_MATH && questionScore === 3)
                        ) {
                            isCorrect = true;
                        }
                        break;
                    case TYPE_OPEN_SHORT_TYPE_1:
                        if (answer[0] === answers[0].body) {
                            isCorrect = true;
                            questionScore = 2;
                        }
                        break;
                    case TYPE_OPEN_SHORT_STRUCTURED:
                        for (let i = 0, length = answerKeys.length; i < length; ++i) {
                            let key = answerKeys[i];
                            if (answer[key] === answers[key].body) {
                                questionScore++;
                            }
                        }
                        if (questionScore === 2) {
                            isCorrect = true;
                        }
                        break;
                    default:
                        break;
                }
                score += questionScore;
                if (isCorrect) {
                    correctAnswersCount++;
                }
            }
        }

        let testDuration = (this.props.finishTime.getTime() - this.props.startTime.getTime()) / 1000,
            testDurationMinutes = Math.floor(testDuration / 60);

        const maxTestDuration = questionsCount * SECONDS_FOR_QUESTION;
        const maxTestDurationMinutes = Math.floor(maxTestDuration / 60);
        const maxTestDurationSeconds = maxTestDuration % 60;

        return (
            <div className="test-result">
                <div className="title">Результат</div>
                <div className="sub-title">{this.props.testTitle}</div>
                <p>Правильних відповідей <b className={this.props.isCheating ? ' text-danger' : ''}>{correctAnswersCount}</b></p>
                <p>Всього завдань <b>{questionsCount}</b></p>
                <p>Тестовий бал <b>{score}</b></p>
                <p>Максимальний тестовий бал в цьому тесті <b>{maxScore}</b></p>
                {this.props.subject === SUBJECT_HISTORY && <TestResultRatingScore testScore={score} maxScore={maxScore}/>}
                <p>
                    Витрачено часу <b>{testDurationMinutes} хв.</b> з запропонованих {maxTestDurationMinutes} хв.
                    {maxTestDurationSeconds > 0 ? ' ' + maxTestDurationSeconds + ' c' : ''}
                </p>
                <TestResultTimestamp time={this.props.finishTime}/>
            </div>
        );
    }
}