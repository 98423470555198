import api from './abstract';

const profileApi = {
    saveInfo(field, value) {
        let data = {};
        data[field] = value;
        return api.post(
            '/profile/edit-info',
            data
        );
    },
    changePassword(oldPassword, password, passwordRepeat) {
        return api.post(
            '/profile/change-password',
            {
                oldPassword: oldPassword,
                password: password,
                passwordRepeat: passwordRepeat
            }
        );
    },
    getTestPassageHistory() {
        return api.get('/cabinet/test-passage-history');
    }
};

export default profileApi;