import React from 'react';
import GoogleMap from "../base/GoogleMap";
import CourseRequestForm from "./CourseRequestForm";
import {CONTACT} from "../../constants/settings";

export default class Contacts extends React.Component {
    render () {
        let phoneList = [];
        for (let phone in CONTACT.phones) {
            let icons = [<i key="phone" className="fas fa-phone"></i>];
            for (let iconNumber in CONTACT.phones[phone]) {
                let icon = CONTACT.phones[phone][iconNumber];
                icons.push(<i key={icon} className={"fab fa-" + icon}></i>);
            }
            phoneList.push(<span key={phone}>{icons}{phone} </span>);
        };
        return (
            <div className="contact-form-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Контактна інформація</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul className="list-unstyled li-space-lg">
                                        <li className="address">
                                            Якщо у вас є запитання напишіть нам або зателефонуйте
                                        </li>
                                        <li>
                                            <i className="fas fa-map-marker-alt"></i> {CONTACT.address}
                                        </li>
                                        <li>
                                            {phoneList}
                                        </li>
                                        <li>
                                            <i className="fas fa-envelope"></i>
                                            <a href={"mailto:" + CONTACT.email} className="turquoise">
                                                {CONTACT.email}
                                            </a>
                                        </li>
                                    </ul>
                                    <CourseRequestForm />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <GoogleMap query="ул. Титова, Днипро, Днепропетровская область, 49000" zoom={13} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}