import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import Test from "./Test";
import ModalPreloader from "../../../base/ModalPreloader";
import znoTestsApi from "../../../../api/znoTests";

const mapStateToProps = state => ({
    loggedIn: state.auth.loggedIn
});

const ZnoTest = ({loggedIn, subject}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isAllowed, setIsAllowed] = useState(true);
    const [currentTest, setCurrentTest] = useState({title: '', questions: []});
    const { testId } = useParams();

    useEffect(() => {
        setIsLoaded(false);
        setIsAllowed(true);
        znoTestsApi.getTestQuestionList(subject, testId).then(response => {
            setCurrentTest(response.data);
            setIsLoaded(true);
        }).catch(error => {
            setIsLoaded(true);
            setIsAllowed(false);
        });
    }, [testId, subject, loggedIn]);

    if (!isLoaded) {
        return <ModalPreloader />;
    }

    if (!isAllowed || currentTest.subject !== subject) {
        return <Navigate to={'/materials/' + subject + '/zno-tests'} />;
    }

    return <Test id={testId} title={currentTest.title} questions={currentTest.questions} loggedIn={loggedIn} subject={subject} />;
}

export default connect(mapStateToProps)(ZnoTest);