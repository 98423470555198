import React from 'react';
import {Routes, Route, useParams} from 'react-router-dom';
import Synopsis from "./synopsis/Synopsis";
import ZnoTests from "./tests/ZnoTests";
import VideoLectures from "./video_lectures/VideoLectures";
import NotFound from "../NotFound";
import "./Materials.css";

const Materials = () => {
    let {subject} = useParams();
    if (subject !== 'history' && subject !== 'math' && subject !== 'ukrmova') {
        return <NotFound />;
    }

    return <div className="zno-materials-page">
        <Routes>
            <Route path="synopsis">
                <Route index element={<Synopsis subject={subject} />} />
                <Route path=":id" element={<Synopsis subject={subject} />} />
            </Route>
            <Route path="zno-tests/*" element={<ZnoTests subject={subject} />} />
            <Route path="video-lectures">
                <Route index element={<VideoLectures />} />
                <Route path=":id" element={<VideoLectures />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    </div>;
}

export default Materials;