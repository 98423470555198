import PropTypes from 'prop-types';
import styles from './Preloader.module.css';

const Preloader = ({color}) => {
    const classNames = [styles.preloader];
    switch (color) {
        case 'primary':
            classNames.push(styles.primary);
            break;
        case 'secondary':
            classNames.push(styles.secondary);
            break;
        default:
            break;
    }

    return(
        <div className={classNames.join(' ')}>
            <div className={styles.skWave + ' sk-wave'}>
                <div className={styles.skRect + ' ' + styles.skRect1}></div>
                <div className={styles.skRect + ' ' + styles.skRect2}></div>
                <div className={styles.skRect + ' ' + styles.skRect3}></div>
                <div className={styles.skRect + ' ' + styles.skRect4}></div>
                <div className={styles.skRect + ' ' + styles.skRect5}></div>
            </div>
        </div>
    );
};

Preloader.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary'])
};

export default Preloader;