import React from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import styles from './Notifications.module.css';
import NotificationMessage from "./NotificationMessage";

const mapStateToProps = state => ({
    notifications: state.notifications
});

class NotificationContainer extends React.Component {
    static propTypes = {
        notifications: PropTypes.arrayOf(PropTypes.object).isRequired
    };

    render() {
        if (!this.props.notifications.length) {
            return null;
        }

        const notifications = this.props.notifications.map(
            notification => <NotificationMessage key={notification.id} {...notification} />
        )

        return (
            <div className={styles.container}>
                {notifications}
            </div>
        );
    }
}

export default connect(mapStateToProps)(NotificationContainer);