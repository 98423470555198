import React from 'react';
import PropTypes from "prop-types";
import {Routes, Route} from 'react-router-dom';
import ZnoTestCategoryList from "./ZnoTestCategoryList";
import ZnoTest from "./test/ZnoTest";
import './ZnoTests.css';
import NotFound from "../../NotFound";

export default class ZnoTests extends React.Component {
    static propTypes = {
        subject: PropTypes.string.isRequired
    };

    render() {
        return (
            <Routes>
                <Route exact path={'/'} element={<ZnoTestCategoryList subject={this.props.subject} />}/>
                <Route path={'test/:testId'} element={<ZnoTest subject={this.props.subject} />}/>
                <Route path="*" element={<NotFound />} />
            </Routes>
        );
    }
}