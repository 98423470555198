import React from 'react';
import {SECONDS_FOR_QUESTION} from '../../../../constants/settings';
import PropTypes from 'prop-types';

export default class Timer extends React.Component {
    constructor(props) {
        super(props);

        this.timeout = null;

        this.state = {
            stopTime: null,
            secondsLeft: null
        };
    }

    static propTypes = {
        questionsCount: PropTypes.number.isRequired
    };

    init = () => {
        let now = Math.floor(Date.now() / 1000);
        this.setState({
            stopTime: now + this.props.questionsCount * SECONDS_FOR_QUESTION
        });
        this.tick();
        this.timeout = setInterval(this.tick, 1000);
    };

    tick = () => {
        if (!this.state.stopTime) {
            return;
        }
        let secondsLeft = this.state.stopTime - Math.floor(Date.now() / 1000);
        if (secondsLeft < 1) {
            secondsLeft = 0;
            this.stop();
        }
        this.setState({
            secondsLeft: secondsLeft
        });
    };

    stop = () => {
        clearTimeout(this.timeout);
    };

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.stop();
    };

    render() {
        let contents = '';
        if (this.state.secondsLeft !== null) {
            let minutes,
                seconds,
                spanClass;
            if (this.state.secondsLeft > 0) {
                minutes = Math.floor(this.state.secondsLeft / 60);
                seconds = this.state.secondsLeft % 60;
                if (minutes >= 7) {
                    spanClass = 'alert-success';
                } else if (minutes > 0) {
                    spanClass = 'alert-warning';
                } else {
                    spanClass = 'alert-danger';
                }
            } else {
                minutes = 0;
                seconds = 0;
                spanClass = 'alert-danger';
            }
            if (minutes < 10) {
                contents += '0';
            }
            contents += minutes + ':';
            if (seconds < 10) {
                contents += '0';
            }
            contents += seconds;
            contents = <span className={spanClass}>{contents}</span>;
        }

        return (
            <div className="test-timer">
                {contents}
            </div>
        );
    }
}