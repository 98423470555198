import React from "react";
import PropTypes from "prop-types";
import SynopsisCategory from "./SynopsisCategory";

export default class SynopsisList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isCollapsed: !!this.props.activeItemId
        };

        this.timeout = null;
    }

    static propTypes = {
        list: PropTypes.arrayOf(PropTypes.object),
        activeItemId: PropTypes.string,
        subject: PropTypes.string.isRequired
    };

    toggle = () => {
        this.setState(state => {
            return {
                isCollapsed: !state.isCollapsed
            };
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeItemId !== prevProps.activeItemId) {
            if ((!this.props.activeItemId && this.state.isCollapsed)
                || (this.props.activeItemId && !this.state.isCollapsed)
            ) {
                this.toggle();
            }
        }
    }

    render() {
        let wrapperClasses = ['synopsis-list-wrapper'];
        if (this.state.isCollapsed) {
            wrapperClasses.push('collapsed');
        }
        if (!this.props.activeItemId) {
            wrapperClasses.push('static');
        }

        return(
            <section className={wrapperClasses.join(' ')}>
                <div className="synopsis-list category-list">
                    {this.props.list.map(item => {
                        return <SynopsisCategory key={item.id}
                             activeItemId={this.props.activeItemId}
                             subject={this.props.subject}
                             {...item}
                        />
                    })}
                </div>
                <button className="synopsis-list-toggler" type="button" onClick={this.toggle}>
                    <span className="synopsis-list-toggler-icon toggler-bar"></span>
                    <span className="synopsis-list-toggler-icon toggler-bar"></span>
                    <span className="synopsis-list-toggler-icon toggler-bar"></span>
                </button>
            </section>
        );
    }
}