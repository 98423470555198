import React from 'react';
import {CONTACT} from "../constants/settings";
import SocialIcon from "./base/SocialIcon";

export default class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-col">
                                <h4>Важливо</h4>
                                <p>
                                    Купуючи наші конспекти у шахраїв, ви позбавляєте себе можливості їх оновлення
                                    відповідно до змін Програми, особистих консультацій викладача та доступу до інших матеріалів сайту.
                                    Поважайте чужу працю, ми робимо для вас найкращі матеріали
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-col last">
                                <h4>Ми в соціальних мережах</h4>
                                <SocialIcon name="facebook-f" url={CONTACT.facebook} />
                                <SocialIcon name="instagram" url={CONTACT.instagram} />
                                <SocialIcon name="paper-plane" url={CONTACT.telegram} iconClass="fa" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}