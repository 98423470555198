import React from 'react';
import Info from './Info';
import Password from './Password';

export default class Settings extends React.Component {
    render() {
        return(
            <div className="settings-page container">
                <div className="card">
                    <div className="card-header card-header-success">
                        <h4 className="card-title">Налаштування</h4>
                    </div>
                    <div className="card-body">
                        <div className="settings-wrapper table-responsive">
                            <table className="table table-hover">
                                <tbody>
                                    <Info/>
                                    <Password/>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}