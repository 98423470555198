import axios from 'axios';
import {BASE_API_URL} from '../constants/api';
import {updateToken} from "../reducers/auth";
import {store} from '../store';
import eventService from '../services/events';

const api = {
    getAuthToken: function () {
        return {
            headers: {
                "Authorization": 'Bearer ' + store.getState().auth.token
            }
        };
    },
    extractTokenFromHeaders(response) {
        const {token} = response.headers;
        if (token) {
            store.dispatch(updateToken(token));
        }
    },
    get: function (url, config) {
        config = Object.assign(this.getAuthToken(), config);

        const promise = axios.get(BASE_API_URL + url, config);

        promise.then(
            response => {
                this.extractTokenFromHeaders(response);
            }
        ).catch(error => {
            if (error.response && (error.response.status === 403 || error.response.status === 401)) {
                eventService.trigger('unauthorizedAction');
            }
        });

        return promise;
    },
    post: function (url, data, config) {
        config = Object.assign(this.getAuthToken(), config);

        const promise = axios.post(BASE_API_URL + url, data, config);

        promise.then(
            response => {
                this.extractTokenFromHeaders(response);
            }
        ).catch(error => {
            if (error.response && (error.response.status === 403 || error.response.status === 401)) {
                eventService.trigger('unauthorizedAction');
            }
        });

        return promise;
    }
};

export default api;