import AnswerButtonBlock from "./AnswerButtonBlock";
import React from "react";

const AnswerOpen = ({answers, isTestFinished, toggleTestIsFinished, goToNextQuestion}) => {
    const score = answers[0] && answers[0].score;

    return <>
        <div className="answer-select">
            <div className="answer-variants-select-title">
                Примітка:
            </div>
            <div>
                Це завдання відкритого типу з розгорнутою відповіддю, воно оцінюється екзаменатором за спеціальними
                критеріями оцінювання. Під час розрахунку бала за це завдання, на сайті ЗНО-онлайн використовується
                спеціальна формула розрахунку в залежності від якості виконання інших завдань тесту.
                Максимальна кількість балів за це завдання: <b>{score}</b>.
            </div>
        </div>
        <AnswerButtonBlock
            isTestFinished={isTestFinished}
            toggleTestIsFinished={toggleTestIsFinished}
            goToNextQuestion={goToNextQuestion}
        />
    </>;
};

export default AnswerOpen;