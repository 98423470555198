import React from 'react';
import Form from "../base/Form";
import {connect} from 'react-redux';
import {GOOGLE_RECAPTCHA_KEY} from "../../constants/settings";
import contactApi from "../../api/contact";
import Preloader from "../base/preloader/Preloader";

const mapStateToProps = state => ({
    auth: state.auth
});

class CourseRequestForm extends Form {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            phone: '',
            email: '',
            message: '',
            isInProcess: false,
            errors: {},
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handlePhoneChange = (event) => {
        event.preventDefault();
        let value = event.target.value;
        if (value.length > 0 && /^\+?\d{0,12}$/.test(value) === false) {
            return false;
        }
        this.setState({
            phone: value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.isInProcess) {
            return false;
        }
        this.setState({
            isInProcess: true,
            message: '',
            errors: {}
        });
        let {fullName, phone, email} = this.state;
        let self = this;
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(GOOGLE_RECAPTCHA_KEY, {action: 'submit'})
                .then(
                    token => {
                        contactApi.submitCourseRequest(token, fullName, phone, email).then(
                            response => {
                                self.setState({
                                    isInProcess: false
                                });
                                let data = response.data;
                                if (data.errors) {
                                    self.setState({
                                        errors: data.errors
                                    });

                                    return false;
                                }

                                self.setState({
                                    message: 'Дякую, ми з зв\'яжемося з Вами найближчим часом'
                                });
                            },
                            () => self.setState({isInProcess: false})
                        );
                    },
                    () => {
                        self.setState({
                            errors: {
                                isInProcess: false,
                                recaptchaToken: 'Ваша поведінка схожа на бота.'
                            }
                        });
                    }
                );
        });
    };

    render() {
        if (this.props.auth.loggedIn) {
            return null
        }
        let overlay = null;
        if (this.state.isInProcess) {
            overlay = <div className="contact-form-overlay">
                <Preloader color="primary" />
            </div>;
        } else if (this.state.message !== '') {
            overlay = <div className="contact-form-overlay">
                <div>{this.state.message}</div>
            </div>;
        } else if (this.state.errors.reCaptchaToken) {
            overlay = <div className="contact-form-overlay">
                <div>{this.state.errors.reCaptchaToken}</div>
            </div>;
        }
        return(
            <form onSubmit={this.handleSubmit} className="contact-form">
                <div className="form-group">
                    <input className={this.getFieldClassName('fullName', ['form-control-input'])}
                           onChange={this.handleChange}
                           required
                           placeholder="Ім'я"
                           name="fullName"
                           value={this.state.fullName}
                    ></input>
                    <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                    <input className={this.getFieldClassName('phone', ['form-control-input'])}
                           onChange={this.handlePhoneChange}
                           required
                           placeholder="Телефон"
                           name="phone"
                           value={this.state.phone}
                    ></input>
                    <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                    <input className={this.getFieldClassName('email', ['form-control-input'])}
                           onChange={this.handleChange}
                           placeholder="Адреса електронної пошти"
                           type="email"
                           name="email"
                           value={this.state.email}
                    ></input>
                    <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                    <button type="submit" className="form-control-submit-button">Подати заявку</button>
                </div>
                <div className="form-message">
                    <div className="h3 text-center hidden"></div>
                </div>
                {overlay}
            </form>
        );
    };
}

export default connect(mapStateToProps)(CourseRequestForm);