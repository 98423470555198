import React from 'react';
import {Accordion} from "react-bootstrap";
import PropTypes from "prop-types";

export default class TestPassageHistoryCard extends React.Component {
    static propTypes = {
        testId: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        results: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string.isRequired,
            score: PropTypes.shape({
                actual: PropTypes.number.isRequired,
                max: PropTypes.number.isRequired
            }).isRequired,
            answers: PropTypes.shape({
                actual: PropTypes.number.isRequired,
                max: PropTypes.number.isRequired
            }).isRequired,
            timeSpent: PropTypes.number.isRequired
        }))
    };

    render() {
        return (
            <Accordion.Item eventKey={this.props.testId}>
                <Accordion.Header>
                    {this.props.title}
                </Accordion.Header>
                <Accordion.Body>
                    <table className="test-passage-session">
                        <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Правильних відповідей</th>
                            <th>Тестовий бал</th>
                            <th>Часу витрачено</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.results.map((session, index) => {
                            const minutesSpent = Math.floor(session.timeSpent / 60),
                                secondsSpent = session.timeSpent % 60;
                            return <tr key={index}>
                                <td>{session.date}</td>
                                <td>{session.answers.actual} з {session.answers.max}</td>
                                <td>{session.score.actual} з {session.score.max}</td>
                                <td>{minutesSpent}хв. {secondsSpent}с.</td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </Accordion.Body>
            </Accordion.Item>
        );
    }
}