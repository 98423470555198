export const GOOGLE_RECAPTCHA_KEY = '6LeOQLQZAAAAAMkc2QIwHa65CW-hyc4kbUNczFa-';
export const CONTACT = {
    phones: {
        '+380979032809': [],
        '+380501234567': ['viber', 'telegram'],
    },
    email: 'zno.dp.ua@gmail.com',
    address: '49055 м. Дніпро, вул. Титова',
    facebook: 'https://www.facebook.com/pidgotovka_do_zno_-106880164374129/',
    instagram: 'https://www.instagram.com/pidgotovka_do_zno_/',
    telegram: 'https://t.me/joinchat/AAAAAE8SYXgiqzOJo0sy5Q'
};
export const SECONDS_FOR_QUESTION = 90;
export const NEXT_ZNO_DATE = '2022-08-01';