import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../modal/Modal";
import CloseButton from "./CloseButton";
import './ModalDialog.css';

export default class ModalDialog extends React.Component {
    static propTypes = {
        closeHandler: PropTypes.func,
        wrapperClasses: PropTypes.arrayOf(PropTypes.string),
        contentClasses: PropTypes.arrayOf(PropTypes.string)
    };

    render() {
        return <Modal wrapperClasses={this.props.wrapperClasses || []} contentClasses={this.props.contentClasses || []}>
            {this.props.closeHandler && <CloseButton clickHandler={this.props.closeHandler}/>}
            {this.props.children}
        </Modal>;
    }
}