import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import ModalPreloader from "../../base/ModalPreloader";
import PageTitle from "../../base/PageTitle";
import {fetchVideoLectures} from "../../../reducers/videoLectures";
import Category from "./Category";
import ErrorMessage from "../../base/errorMessage/ErrorMessage";
import './VideoLectures.css';

const VideoLectures = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const {isLoaded, list, error} = useSelector(state => state.videoLectures);
    const loggedIn = useSelector(state => state.auth.loggedIn);

    useEffect(() => {
        dispatch(fetchVideoLectures())
    }, [loggedIn, dispatch]);

    if (error) {
        return <ErrorMessage>
            Не вдалося завантажити список лекцій, спробуйте ще раз. Якщо помилка повториться, повідомте викладача.
        </ErrorMessage>;
    }

    if (!isLoaded) {
        return <ModalPreloader />;
    }

    return (
        <div className="video-lectures">
            <PageTitle pageTitle="Відео-лекції для підготовки до ЗНО з історії України"/>
            <div className="video-lecture-list category-list">
                {list.map(category => {
                    return <Category key={category.id} currentLectureId={Number(id)} {...category}/>;
                })}
            </div>
        </div>
    );
}

export default VideoLectures;