import React, {Component} from "react";
import PropTypes from "prop-types";
import CheatingWarning from "./CheatingWarning";
import AnswerButtonBlock from "./AnswerButtonBlock";
import PressingButtonReminder from "./PressingButtonReminder";

export default class AnswerOpenShort extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: {}
        };
    }

    static propTypes = {
        questionId: PropTypes.number.isRequired,
        storeAnswer: PropTypes.func.isRequired,
        toggleTestIsFinished: PropTypes.func.isRequired,
        isTestFinished: PropTypes.bool.isRequired,
        isOpenedTwice: PropTypes.bool.isRequired,
        subject: PropTypes.string.isRequired
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.questionId !== this.props.questionId && Object.keys(this.state.answers).length > 0) {
            this.setState({answers: {}});
        }
    }

    onInput = (e) => {
        let {target} = e;
        this.setState(state => {
            if (target.value !== '') {
                state.answers[target.name] = target.value.replace(',', '.');
            } else {
                delete state.answers[target.name];
            }

            return state;
        });
    };

    render() {
        let storeAnswerCallback;

        if (Object.keys(this.state.answers).length > 0 && this.props.answer === null) {
            storeAnswerCallback = () => this.props.storeAnswer(this.props.questionId, this.state.answers);
        }

        const {answers} = this.props;

        return <>
            <div className="answer-select">
                <div className="answer-variants-select-title">
                    Впишіть {this.props.answers.length > 1 ? 'відповіді' : 'відповідь'}:
                </div>
                <div className="answer-variants-select-wrapper">
                    <div className="answer-inputs">
                        {answers.map((answer, index) => {
                            let classNames = ['answer-variants-input-label'],
                                onInput = null,
                                value = this.state.answers[index] || '',
                                correctAnswer;
                            if (!this.props.answer) {
                                onInput = this.onInput;
                            }
                            if (this.props.answer !== null) {
                                value = this.props.answer[index] || '';
                                const isCorrect = value === answer.body;
                                let statusClassName = isCorrect ? 'is-correct' : 'is-incorrect';
                                classNames.push(statusClassName);
                                if ((!this.props.isOpenedTwice || this.props.isTestFinished) && !isCorrect) {
                                    correctAnswer = <span className="correct-answer">Вірна відповідь: {answer.body}</span>;
                                }
                            }
                            let answerLabel = answers.length > 1 ? index + 1 + '. ' : '';
                            return <div key={'' + index + '_' + answer.body}><label className={classNames.join(' ')}>
                                {answerLabel}<input type="text" name={index} onInput={onInput} value={value} readOnly={!!this.props.answer || this.props.isTestFinished}/>
                            </label>{correctAnswer}</div>;
                        })}
                    </div>
                    {!!storeAnswerCallback && <PressingButtonReminder isOpenedTwice={this.props.isOpenedTwice} />}
                </div>
            </div>
            {this.props.isOpenedTwice && !this.props.isTestFinished && <CheatingWarning />}
            <AnswerButtonBlock
                goToNextQuestion={this.props.goToNextQuestion}
                storeAnswer={storeAnswerCallback}
                toggleTestIsFinished={this.props.toggleTestIsFinished}
                isTestFinished={this.props.isTestFinished}
            />
        </>;
    }
}