import React from "react";
import PropTypes from "prop-types";

export default class ListElement extends React.Component {
    static propTypes = {
        classNames: PropTypes.arrayOf(PropTypes.string),
        onClick: PropTypes.func
    };

    render() {
        let classNames = this.props.classNames || [];
        classNames.includes('list-element') || classNames.push('list-element');
        const onClick = this.props.onClick || null;
        return (
            <li className={classNames.join(' ')} onClick={onClick}>
                {this.props.children}
            </li>
        );
    }
}