import React from 'react';
import PropTypes from 'prop-types';
import znoTestsApi from "../../../../api/znoTests";
import QuestionList from "./QuestionList";
import Question from "./Question";
import TestResult from "./TestResult";
import ModalDialog from "../../../base/modalDialog/ModalDialog";
import Timer from "./Timer";

const STORAGE_KEY = 'openedTabs';

export default class Test extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentQuestionNumber: 0,
            answers: {},
            isFinished: false,
            isResultShown: false,
            startTime: new Date(),
            finishTime: new Date(),
            isOpenedTwice: false,
            isCheating: false
        };
    }

    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.string.isRequired,
        questions: PropTypes.arrayOf(PropTypes.object),
        loggedIn: PropTypes.bool,
        subject: PropTypes.string.isRequired
    };

    pushMessage = (payload) => {
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(payload));
        window.localStorage.removeItem(STORAGE_KEY);
    }

    broadcastListener = (event) => {
        const {key, newValue} = event;
        if (key !== STORAGE_KEY || !newValue) {
            return;
        }
        const value = JSON.parse(newValue);
        const currentTestId = this.props.id;
        if (value.openedTest === currentTestId) {
            this.pushMessage({alreadyOpenedTest: this.props.id});
            this.setState({isOpenedTwice: true});
        }
        if (value.alreadyOpenedTest === currentTestId) {
            this.setState({isOpenedTwice: true});
        }
        if (value.closedTest === currentTestId) {
            this.setState({isOpenedTwice: false});
            this.pushMessage({openedTest: currentTestId});
        }
    };

    componentDidMount() {
        window.addEventListener('storage', this.broadcastListener);
        this.pushMessage({openedTest: this.props.id});
    }

    componentWillUnmount() {
        if (this.props.id) {
            this.pushMessage({closedTest: this.props.id});
        }
        window.removeEventListener('storage', this.broadcastListener);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.id !== this.props.id) {
            if (prevProps.id) {
                this.pushMessage({closedTest: prevProps.id});
            }
        }
        if (prevState.isFinished === false && this.state.isFinished === true) {
            this.saveResults().then(response => {
                if (response.data && response.data.isGuest && this.props.loggedIn) {
                    this.setState({isCheating: true});
                }
            });
        }
    }

    setCurrentQuestion = (questionNumber) => {
        this.setState({currentQuestionNumber: questionNumber});
    }

    storeAnswer = (questionId, answer) => {
        this.setState(state => {
            let answers = state.answers;
            answers[questionId] = answer;

            return {answers: answers};
        });
    };

    saveResults = () => {
        let timeSpent = Math.round((this.state.finishTime.getTime() - this.state.startTime.getTime()) / 1000);

        return znoTestsApi.saveResults(this.props.id, this.state.answers, timeSpent);
    };

    showResults = () => {
        this.setState({
            isResultShown: true
        });
    };

    toggleIsFinished = () => {
        this.setState(state => {
            if (!state.isFinished) {
                return {
                    isFinished: true,
                    isResultShown: true,
                    finishTime: new Date()
                };
            }

            return {
                isFinished: false,
                isResultShown: false,
                currentQuestionNumber: 0,
                answers: {},
                startTime: new Date(),
                isCheating: false
            };
        });
    };

    render() {
        let {currentQuestionNumber} = this.state,
            currentQuestion = null,
            currentQuestionAnswer = null,
            goToNextQuestion = null,
            questionsCount = this.props.questions.length;
        if (currentQuestionNumber !== null) {
            currentQuestion = this.props.questions[currentQuestionNumber] || null;
            if (currentQuestion !== null && this.state.answers.hasOwnProperty(currentQuestion.id)) {
                currentQuestionAnswer =  this.state.answers[currentQuestion.id];
            }
            if (currentQuestionNumber < questionsCount - 1) {
                goToNextQuestion = () => this.setCurrentQuestion(currentQuestionNumber + 1);
            }
        }

        return (
            <div className="zno-test container">
                {!this.state.isFinished && <Timer questionsCount={questionsCount} />}
                <QuestionList
                    list={this.props.questions}
                    currentQuestionNumber={this.state.currentQuestionNumber}
                    setCurrentQuestion={this.setCurrentQuestion}
                    answeredQuestions={Object.keys(this.state.answers)}
                />
                {this.state.isFinished && <button className="btn btn-outline-primary" onClick={this.showResults}>Результат</button>}
                {currentQuestion && <Question
                    goToNextQuestion={goToNextQuestion}
                    storeAnswer={this.storeAnswer}
                    toggleTestIsFinished={this.toggleIsFinished}
                    answer={currentQuestionAnswer}
                    isTestFinished={this.state.isFinished}
                    isOpenedTwice={this.state.isOpenedTwice}
                    subject={this.props.subject}
                    {...currentQuestion}
                />}
                {this.state.isFinished && this.state.isResultShown && <ModalDialog wrapperClasses={['test-result-wrapper']} closeHandler={() => this.setState({isResultShown: false})}>
                    <TestResult
                        testTitle={this.props.title}
                        questionList={this.props.questions}
                        answers={this.state.answers}
                        startTime={this.state.startTime}
                        finishTime={this.state.finishTime}
                        isCheating={this.state.isCheating}
                        subject={this.props.subject}
                    />
                </ModalDialog>}
            </div>
        );
    }
}