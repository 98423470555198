import React from "react";
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

const NavItemDropdown = ({text, items}) => {
    const itemsAmount = items.length;
    return <li className="nav-item dropdown">
        <span className="nav-link dropdown-toggle">{text}</span>
        <div className="dropdown-menu">
            {items.map((item, i) => {
                let separator = i < itemsAmount - 1 ? <div className="dropdown-items-divide-hr"></div> : null;
                const itemText = <span className="item-text">{item.text}</span>;
                let dropdownItem;
                if (item.href) {
                    dropdownItem = <NavLink className="dropdown-item" to={item.href}>
                        {itemText}
                    </NavLink>;
                } else {
                    dropdownItem = <span className="dropdown-item link">
                        {itemText}
                    </span>;
                }

                return <React.Fragment key={item.text}>{dropdownItem}{separator}</React.Fragment>;
            })}
        </div>
    </li>;
};

NavItemDropdown.propTypes = {
    text: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object)
};

export default NavItemDropdown;