import React from 'react';

export default class NotFound extends React.Component {
    render() {
        return (
            <h2 className="text-center">
                Сторінку не знайдено
            </h2>
        );
    }
}