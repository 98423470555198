import React from 'react';
import PropTypes from 'prop-types';
import AnswerVariantLabel from "./AnswerVariantLabel";
import AnswerSelectionMarker from "./AnswerSelectionMarker";
import AnswerSelection from "./AnswerSelection";
import AnswerButtonBlock from "./AnswerButtonBlock";
import CheatingWarning from "./CheatingWarning";

export default class AnswerSeveral extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: {}
        };
    }

    static propTypes = {
        questionId: PropTypes.number.isRequired,
        storeAnswer: PropTypes.func.isRequired,
        toggleTestIsFinished: PropTypes.func.isRequired,
        isTestFinished: PropTypes.bool.isRequired
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.questionId !== this.props.questionId && Object.keys(this.state.answers).length > 0) {
            this.setState({answers: {}});
        }
    }

    onCheckboxChange = (e) => {
        let {target} = e;
        this.setState(state => {
            if (target.checked) {
                if (Object.keys(state.answers).length < 3) {
                    state.answers[target.value] = true;
                }
            } else {
                delete state.answers[target.value];
            }

            return state;
        });
    };

    getAnswerSelectionInput = (answer) => {
        let {index} = answer,
            onChange,
            isChecked,
            isReadonly = false,
            isCorrect = false,
            isIncorrect = false;
        if (this.props.answer !== null) {
            isChecked = this.props.answer[index] === true;
            isReadonly = true
            isCorrect = (!this.props.isOpenedTwice || this.props.isTestFinished) && !!answer.isCorrect;
            isIncorrect = (!this.props.isOpenedTwice || this.props.isTestFinished) && isChecked && !isCorrect;
        } else {
            isChecked = this.state.answers[index] === true;
        }
        isReadonly = isReadonly || this.props.isTestFinished;
        if (isReadonly) {
            onChange = (e) => {
                e.preventDefault();

                return false;
            };
        } else {
            onChange = this.onCheckboxChange;
        }

        return <AnswerSelectionMarker isReadOnly={isReadonly} isCorrect={isCorrect} isIncorrect={isIncorrect}>
            <input type="checkbox" name="input-name[]" value={index} onChange={onChange} checked={isChecked} readOnly={isReadonly} />
        </AnswerSelectionMarker>;
    };

    render() {
        let selectionHead = [],
            selectionBody = [],
            storeAnswerCallback;
        let variants = this.props.answers.map((answer, index) => {
            let label = <AnswerVariantLabel index={index} isNumeric={true}/>;
            selectionHead.push(label);

            selectionBody.push(this.getAnswerSelectionInput(answer));

            return <li key={index}>{label} <span dangerouslySetInnerHTML={{__html: answer.body}}></span></li>;
        });

        if (this.props.answer === null && Object.keys(this.state.answers).length !== 0) {
            storeAnswerCallback = () => this.props.storeAnswer(this.props.questionId, this.state.answers);
        }

        return (
            <>
                <ul className="answer-variants">
                    {variants}
                </ul>
                <AnswerSelection
                    head={selectionHead}
                    rows={[selectionBody]}
                    showWarning={!!storeAnswerCallback}
                    isOpenedTwice={this.props.isOpenedTwice}
                />
                {this.props.isOpenedTwice && !this.props.isTestFinished && <CheatingWarning />}
                <AnswerButtonBlock
                    goToNextQuestion={this.props.goToNextQuestion}
                    storeAnswer={storeAnswerCallback}
                    toggleTestIsFinished={this.props.toggleTestIsFinished}
                    isTestFinished={this.props.isTestFinished}
                />
            </>
        );
    }
}