import {useEffect} from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from 'react-redux';
import {fetchTests} from "../../../reducers/tests";
import ModalPreloader from "../../base/ModalPreloader";
import Category from "./Category";
import ErrorMessage from "../../base/errorMessage/ErrorMessage";

const ZnoTestCategoryList = ({subject}) => {
    const loggedIn = useSelector(state => state.auth.loggedIn);
    const {isLoaded, error, categoryList} = useSelector(state => state.tests[subject]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTests(subject));
    }, [loggedIn, subject, dispatch]);

    if (error) {
        return <ErrorMessage>
            Не вдалося завантажити список тестів, спробуйте ще раз. Якщо помилка повториться, повідомте викладача.
        </ErrorMessage>;
    }

    if (!isLoaded) {
        return <ModalPreloader />;
    }

    return(
        <div className="category-list">
            {categoryList.map(category => {
                return <Category key={category.id} subject={subject} {...category} />;
            })}
        </div>
    );
}

ZnoTestCategoryList.propTypes = {
    subject: PropTypes.string.isRequired
};

export default ZnoTestCategoryList;