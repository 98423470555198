import React from 'react';
import PropTypes from 'prop-types';
import AnswerVariantLabel from "./AnswerVariantLabel";
import AnswerSelectionMarker from "./AnswerSelectionMarker";
import AnswerSelection from "./AnswerSelection";
import AnswerButtonBlock from "./AnswerButtonBlock";
import CheatingWarning from "./CheatingWarning";

export default class AnswerSequence extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: {}
        };
    }

    static propTypes = {
        questionId: PropTypes.number.isRequired,
        storeAnswer: PropTypes.func.isRequired,
        toggleTestIsFinished: PropTypes.func.isRequired,
        isTestFinished: PropTypes.bool.isRequired
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.questionId !== this.props.questionId && Object.keys(this.state.answers).length > 0) {
            this.setState({answers: {}});
        }
    }

    onAnswerChange = (e) => {
        let {target} = e;

        this.setState(state => {
            state.answers[target.name] = target.value;

            return state;
        });
    };

    getSelectionInput = (rowIndex, index) => {
        let isChecked,
            isReadOnly = false,
            onChange,
            isCorrect = false,
            isIncorrect = false;

        if (this.props.answer !== null) {
            isReadOnly = true;
            isChecked = typeof this.props.answer[rowIndex] !== 'undefined' && Number(this.props.answer[rowIndex]) === index;
            isCorrect = (!this.props.isOpenedTwice || this.props.isTestFinished) && rowIndex === index;
            isIncorrect = (!this.props.isOpenedTwice || this.props.isTestFinished) && isChecked && !isCorrect;
        } else {
            isChecked = typeof this.state.answers[rowIndex] !== 'undefined' && Number(this.state.answers[rowIndex]) === index;
        }

        isReadOnly = isReadOnly || this.props.isTestFinished;
        if (isReadOnly) {
            onChange = (e) => {
                e.preventDefault();
                return false;
            };
        } else {
            onChange = this.onAnswerChange;
        }

        return <AnswerSelectionMarker isReadOnly={isReadOnly} isCorrect={isCorrect} isIncorrect={isIncorrect}>
            <input type="radio" name={rowIndex} value={index} onChange={onChange} checked={isChecked} readOnly={isReadOnly} />
        </AnswerSelectionMarker>;
    };

    render() {
        let selectionHead = [<span></span>],
            selectionBody = [],
            storeAnswerCallback;
        for (let i = 0, length = this.props.answers.length; i < length; ++i) {
            selectionBody.push([<AnswerVariantLabel index={i} isNumeric={true} />]);
        }
        let variants = this.props.answers.map((answer, index) => {
            let label = <AnswerVariantLabel index={index} isNumeric={false} />;
            selectionHead.push(label);
            selectionBody.map((element, rowIndex) => {
                element.push(this.getSelectionInput(rowIndex, answer.index));

                return element;
            });

            return <li key={index}>{label} <span dangerouslySetInnerHTML={{__html: answer.body}}></span></li>;
        });

        if (this.props.answer === null && Object.keys(this.state.answers).length !== 0) {
            storeAnswerCallback = () => this.props.storeAnswer(this.props.questionId, this.state.answers);
        }

        return (
            <>
                <ul className="answer-variants">
                    {variants}
                </ul>
                <AnswerSelection
                    head={selectionHead}
                    rows={selectionBody}
                    showWarning={!!storeAnswerCallback}
                    isOpenedTwice={this.props.isOpenedTwice}
                />
                {this.props.isOpenedTwice && !this.props.isTestFinished && <CheatingWarning />}
                <AnswerButtonBlock
                    goToNextQuestion={this.props.goToNextQuestion}
                    storeAnswer={storeAnswerCallback}
                    toggleTestIsFinished={this.props.toggleTestIsFinished}
                    isTestFinished={this.props.isTestFinished}
                />
            </>
        );
    }
}