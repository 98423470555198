import { createSlice } from "@reduxjs/toolkit";

const navbarSlice = createSlice({
    name: 'navbar',
    initialState: {isToggled: false},
    reducers: {
        toggle(state) {
            state.isToggled = !state.isToggled;
        },
        close(state) {
            state.isToggled = false;
        }
    }
});

export const navbarActions = navbarSlice.actions;

export default navbarSlice.reducer;