import React from 'react';
import {Link} from "react-router-dom";

export default class Header extends React.Component {
    render() {
        return (
            <header className="header">
                <div className="header-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="text-container">
                                    <h1><span className="turquoise">Склади успішно ЗНО</span><br />та вступи до омріяного університету</h1>
                                    <p className="p-large">
                                        Вітаємо Вас на ресурсі, розробленому для online підготовки до ЗНО!
                                    </p>
                                    <Link className="btn-solid-lg blinking" to="/contacts">
                                        Замовити курс
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-container">
                                    <img className="img-fluid" src="/images/header-teamwork.svg"
                                         alt="Процес підготовки до ЗНО" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}