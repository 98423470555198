/**
 * @type {
 *      [
 *          {
 *              isFeatured: boolean,
 *              isHighlighted: boolean,
 *              title: string,
 *              subtitle: string,
 *              price: number,
 *              oldPrice: number,
 *              currency: string,
 *              priceStartsWith: boolean,
 *              priceNote: string,
 *              benefits: string[]
 *          }
 *      ]
 *  }
 */
export const PRICES = [
    {
        title: 'Абонемент на місяць',
        price: 190,
        benefits: [
            'Користування в особистому кабінеті конспектами ОНОВЛЕНИХ 32 тем відповідно до ПРОГРАМИ 2023',
            'Міні-курси: персоналії, портрети, дати, терміни, карти, пам\'ятки',
            'Тести з відповідями до кожної теми і до міні-курсів',
            'Розбір тестів і найскладніших питань онлайн',
            'Консультації в Telegram',
            'Відео- і аудіозапис всіх занять (за додаткову плату)'
        ]
    },
    /*{
        title: 'ЗНО/НМТ Самостійний',
        subtitle: 'діє до ЗНО 2023',
        price: 180,
        oldPrice: 395,
        benefits: [
            'Користування в особистому кабінеті конспектами ОНОВЛЕНИХ 32 тем відповідно до ПРОГРАМИ 2023 (для НМТ 13 тем - від Першої світової війни до творення нової України)',
            'Міні-курси: персоналії, портрети, дати, терміни, карти, пам\'ятки',
            'Тести з відповідями до кожної теми і до міні-курсів',
            'Індивідуальні консультації',
            'Чат учасників в Telegram',
            'Розбір тестів і найскладніших питань онлайн',
            'Чек-листи для самоконтролю',
            'Відео- і аудіозапис всіх занять (за додаткову плату)'
        ],
    },*/
    {
        isHighlighted: true,
        title: 'Самостійний ЗНО/НМТ',
        subtitle: 'діє до ЗНО 2024 року',
        price: 480,
        benefits: [
            'Користування в особистому кабінеті конспектами ОНОВЛЕНИХ 32 тем відповідно до ПРОГРАМИ 2023',
            'Міні-курси: персоналії, портрети, дати, терміни, карти, пам\'ятки',
            'Тести з відповідями до кожної теми і до міні-курсів',
            'Індивідуальні консультації',
            'Чат учасників в Telegram',
            'Розбір тестів і найскладніших питань онлайн',
            'Чек-листи для самоконтролю',
            'Відео- і аудіозапис всіх занять (за додаткову плату)'
        ]
    },
    {
        isFeatured: true,
        title: '1 рік',
        subtitle: 'групові заняття з підготовки до ЗНО',
        priceStartsWith: true,
        price: 140,
        currency: 'грн/год',
        priceNote: 'оплата щомісяця',
        benefits: [
            'Заняття з викладачем 1 або 2 рази на тиждень онлайн в міні-групах (2-6 учнів) або індивідуально в Google Meet/Zoom',
            'Безкоштовне тестування учня і консультація з приводу вступу',
            'Користування конспектами, міні-курсами (дати, персоналії, терміни, карти, пам’ятки, плакати) та експрес-курсом в особистому кабінеті',
            'Тести з відповідями до кожної теми і до міні-курсів',
            'Консультації в Telegram, чат учасників',
            'Контроль прогресу',
            'Відео- і аудіозапис всіх занять'
        ]
    }/*,
    {
        title: 'Експрес',
        subtitle: 'Короткі схеми по 32 темам',
        price: 150,
        benefits: [
            'Користування в особистому кабінеті короткими схемами-конспектами ОНОВЛЕНИХ 32 тем відповідно до ПРОГРАМИ 2023',
            'Тести з відповідями до кожної теми і до міні-курсів',
            'Консультації в Telegram',
            'Відео- і аудіозапис всіх занять (за додаткову плату)'
        ]
    }*/
];