import React from 'react';
import {NavLink} from "react-router-dom";
import {CONTACT} from "../../constants/settings";
import NavItemDropdown from "./NavItemDropdown";
import UserMenu from "../profile/UserMenu";
import SocialIcon from "../base/SocialIcon";

export default class NavMenu extends React.Component {
    render() {
        const items = this.props.items.map((item) => {
            if (item.items) {
                return <NavItemDropdown key={item.text} text={item.text} items={item.items} />
            }
            return <li className="nav-item" key={item.href}>
                <NavLink to={item.href} className="nav-link">
                    {item.text}
                </NavLink>
            </li>;
        });
        let classes = 'collapse navbar-collapse';
        if (this.props.isShown) {
            classes += ' show';
        }
        return (
            <div className={classes}>
                <ul className="navbar-nav ml-auto">
                    {items}
                    <UserMenu />
                </ul>
                <div className="nav-item social-icons">
                    <SocialIcon name="facebook-f" url={CONTACT.facebook} />
                    <SocialIcon name="instagram" url={CONTACT.instagram} />
                    <SocialIcon name="paper-plane" url={CONTACT.telegram} iconClass="fa" />
                </div>
            </div>
        );
    }
}