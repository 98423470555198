import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import React from "react";
import { doLogout } from "../../reducers/auth";

const AuthorizedUserMenu = ({username}) => {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(doLogout());
    };

    return <li className="nav-item dropdown">
        <span className="nav-link dropdown-toggle">
            {username}
        </span>
        <div className="dropdown-menu">
            <NavLink className="dropdown-item" to="/account/settings">
                <span className="item-text">Налаштування</span>
            </NavLink>
            <div className="dropdown-items-divide-hr"></div>
            <NavLink className="dropdown-item" to="/account/cabinet">
                <span className="item-text">Особистий кабінет</span>
            </NavLink>
            <div className="dropdown-items-divide-hr"></div>
            <span className="dropdown-item link" onClick={logout}>
                <span className="item-text">Вийти</span>
            </span>
        </div>
    </li>;
};

export default AuthorizedUserMenu;