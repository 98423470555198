import React from 'react';
import AnswerDefault from "./AnswerDefault";
import AnswerSeveral from "./AnswerSeveral";
import AnswerSequence from "./AnswerSequence";
import AnswerMatch from "./AnswerMatch";
import AnswerOpenShort from "./AnswerOpenShort";
import AnswerOpen from "./AnswerOpen";
import TextBlock from "./TextBlock";
import {
    TYPE_DEFAULT,
    TYPE_SEVERAL,
    TYPE_SEQUENCE,
    TYPE_MATCH,
    TYPE_OPEN_SHORT_TYPE_1,
    TYPE_OPEN_SHORT_STRUCTURED,
    TYPE_OPEN
} from "../../../../constants/question-types";

export default class Question extends React.Component {
    render() {
        let {id, body, type, subject} = this.props,
            answersBlock = null;

        switch (type) {
            case TYPE_DEFAULT:
                answersBlock = <AnswerDefault questionId={id} {...this.props} />;
                break;
            case TYPE_SEVERAL:
                answersBlock = <AnswerSeveral questionId={id} {...this.props} />;
                break;
            case TYPE_SEQUENCE:
                answersBlock = <AnswerSequence questionId={id} {...this.props} />;
                break;
            case TYPE_MATCH:
                answersBlock = <AnswerMatch questionId={id} {...this.props} />;
                break;
            case TYPE_OPEN_SHORT_TYPE_1:
            case TYPE_OPEN_SHORT_STRUCTURED:
                answersBlock = <AnswerOpenShort questionId={id} {...this.props} />;
                break;
            case TYPE_OPEN:
                answersBlock = <AnswerOpen {...this.props} />;
                break;
            default:
                return null;
        }

        return (
            <div className="question-wrapper">
                <TextBlock subject={subject}>
                    <div className="question" dangerouslySetInnerHTML={{__html: body}}></div>
                </TextBlock>
                {answersBlock}
            </div>
        );
    }
}