import {createSlice} from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: [],
    reducers: {
        add(state, action) {
            state.push(action.payload);
        },
        remove(state, action) {
            return state.filter(notification => {
                return notification.id !== action.payload.id
                    || notification.dismissAfter !== action.payload.dismissAfter;
            });
        }
    }
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice.reducer;