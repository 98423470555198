import React from 'react';
import TestimonialItem from "./TestimonialItem";
import Swiper from 'react-id-swiper';

export default class Testimonials extends React.Component {
    constructor(props) {
        super(props);

        this.swiperRef = React.createRef();

        this.slideNext = this.slideNext.bind(this);
        this.slidePrev = this.slidePrev.bind(this);
    }
    slideNext() {
        if (this.swiperRef.current !== null && this.swiperRef.current.swiper !== null) {
            this.swiperRef.current.swiper.slideNext();
        }
    }
    slidePrev() {
        if (this.swiperRef.current !== null && this.swiperRef.current.swiper !== null) {
            this.swiperRef.current.swiper.slidePrev();
        }
    }
    render() {
        const swiperParams = {
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        };

        return (
            <div className="slider-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Відгуки</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-container">
                                <img className="img-fluid" src="/images/testimonials-2-men-talking.svg"
                                     alt="Відгуки" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="slider-container">
                                <div className="swiper-container card-slider">
                                    <Swiper ref={this.swiperRef} {...swiperParams}>
                                        <TestimonialItem
                                            imgSrc="/images/maria.jpg"
                                            text="Здравствуйте ) 191 балл у меня )) Все как я хотела ))
                                                Спасибо вам огромное, вы очень талантливый преподаватель,
                                                очень понятно все объясняли и выделяли все самое необходимое))
                                                за 4 месяца с нуля научить истории это практически невозможно 😊"
                                            author="Мария Ведмеденко"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/masha.jpg"
                                            text="Большое Спасибо за ваш труд!!!! У вас  великолепный конспекты
                                                все что нужно и очень интересно подано!!!! Большое спасибо за ваши
                                                трансляции когда была занята всегда их слушала!!!! Удачи!!!
                                                Продолжайте делать то что вы делаете!!! БОЛЬШОЕ ВАМ СПАСИБО!!!!!"
                                            author="Мария Дуб"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/testimonial-1.svg"
                                            text="Спешу сообщить, что я выше всех ее написала. Если бы я случайно не
                                                нашла вашу страницу и ваши конспекты, я б не знаю чтобы бы было!
                                                Спасибо! За вашу работу, за офигенный конспект,
                                                за трансляции, за помощь в Директ! Дай Бог вам много классных учеников,
                                                терпения, и главное не прекращайте то, что делаете! Спасибо ❤️❤️❤️❤️"
                                            author="Аня Колесник"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/vasilieva.jpg"
                                            text="У меня 194 (по ДПА 12). Всё благодаря Вам, спасибо огромное❤❤❤❤
                                                Буду Вас рекламировать будущим одиннадцатиклассникам💋"
                                            author="Юлия Васильева"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/german.jpg"
                                            text="Спасибо большое, очень тронуты! :) Мы Вам очень благодарны,
                                                без Вас ничего бы мы не сделали ❤"
                                            author="Ира и Герман Рыбак"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/testimonial-3.svg"
                                            text="Олеся, я приблизительно написал ЗНО на 165 баллов. Как по мне,
                                                было сложновато, но все же, даже если не ответил правильно, то это моя невнимательность
                                                больше сыграла роль! А так хочу сказать Вам большое СПАСИБО за то,
                                                что помогли подготовиться к ЗНО! Буду Вас как учителя всем рекомендовать.
                                                Еще раз Спасибо! Вы хороший учитель и как человек очень добродушный!"
                                            author="Макс Кузьменко"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/valeria.jpg"
                                            text="У меня 193 балла по ЗНО и 12 по ДПА.
                                                @pidgotovka_do_zno_ - здесь лучший преподаватель по истории"
                                            author="Валерия Бабко"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/liza.jpg"
                                            text="У меня 188. Я довольна результатом. Спасибо огромное за помощь :)"
                                            author="Лиза Ресенчук"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/sonia.jpg"
                                            text="Дааааааа, делюсь радостью. У меня 188. Спасибо Вам большое."
                                            author="Соня Дирявко"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/testimonial-3.svg"
                                            text="У меня 184. Спасибо большое. Отличный результат по ЗНО"
                                            author="Женя Россыпайло"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/testimonial-3.svg"
                                            text="Как и Макс буду Вас рекомендовать если кому-то будет нужна помощь,
                                                я написал примерно на 184, ошибки были лишь из-за того, что немного подзабыл
                                                даты, а так все было не настолько сложно, спасибо, что Вы нас готовили,
                                                хоть я историю брал как 4-й, но мне действительно было интересно ее учить с Вашей помощью,
                                                еще раз спасибо!"
                                            author="Виктор Тараненко"
                                        />
                                        <TestimonialItem
                                            imgSrc="/images/testimonial-2.svg"
                                            text="Это очень хороший репетитор, просто великолепный! Я в восторге от Олеси Анатольевны!
                                                Очень профессионально она работает. Это педагог высокого класса!
                                                Репетитор прекрасные знания дает по истории. Очень эффективные занятия.
                                                Олеся Анатольевна готовится к занятиям, материал она дает нужный,
                                                объясняет его доходчиво. Все мои пожелания педагог учитывает.
                                                Хочу поблагодарить Олесю Анатольевну за прекрасное обучение сына."
                                            author="Юлия Ермоленко"
                                        />
                                    </Swiper>
                                    <div className="swiper-button-next" onClick={this.slideNext}></div>
                                    <div className="swiper-button-prev" onClick={this.slidePrev}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}