import React from 'react';
import PropTypes from 'prop-types';
import PressingButtonReminder from "./PressingButtonReminder";

export default class AnswerSelection extends React.Component {
    static propTypes = {
        head: PropTypes.array.isRequired,
        rows: PropTypes.array.isRequired,
        isSeveral: PropTypes.bool,
        showWarning: PropTypes.bool,
        isOpenedTwice: PropTypes.bool
    };

    static defaultProps = {
        isSeveral: true,
        showWarning: false,
        isOpenedTwice: false
    };

    render() {
        return (
            <div className="answer-select">
                <div className="answer-variants-select-title">
                    Позначте {this.props.isSeveral ? 'відповіді' : 'відповідь'}:
                </div>
                <div className="answer-variants-select-wrapper">
                    <div>
                        <table className="answer-variants-select">
                            <thead>
                            <tr>
                                {this.props.head.map((element, index) => <th key={index}>{element}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.rows.map(((row, index) => {
                                return <tr key={index}>
                                    {row.map((element, elementIndex) => <td key={elementIndex}>{element}</td>)}
                                </tr>;
                            }))}
                            </tbody>
                        </table>
                    </div>
                    {this.props.showWarning && <PressingButtonReminder isOpenedTwice={this.props.isOpenedTwice} />}
                </div>
            </div>
        );
    }
}