import {createSlice} from "@reduxjs/toolkit";
import historyApi from "../api/history";

const videoLecturesSlice = createSlice({
    name: 'videoLectures',
    initialState: {
        isLoaded: false,
        error: false,
        list: []
    },
    reducers: {
        update(state, action) {
            state.isLoaded = action.payload.isLoaded;
            state.error = action.payload.error;
            state.list = action.payload.list;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    }
});

export const videoLecturesActions = videoLecturesSlice.actions;

export const fetchVideoLectures = () => {
    return (dispatch) => {
        dispatch(videoLecturesActions.setError(false));
        historyApi.getVideoLectureList().then(response => {
            dispatch(videoLecturesActions.update({
                isLoaded: true,
                error: response.data.length === 0,
                list: response.data
            }));
        }).catch(e => {
            dispatch(videoLecturesActions.update({
                isLoaded: false,
                error: true,
                list: []
            }));
        });
    };
};

export default videoLecturesSlice.reducer;