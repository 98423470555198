import {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './Modal.module.css';

export default class ModalPortal extends Component {
    constructor(props) {
        super(props);

        this.element = document.createElement('div');

        let wrapperClasses = this.props.wrapperClasses || [];
        wrapperClasses.includes(styles.modalWrapper) || wrapperClasses.push(styles.modalWrapper);

        this.element.className = wrapperClasses.join(' ');
    }

    static propTypes = {
        wrapperClasses: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        wrapperClasses: []
    };

    componentDidMount() {
        document.getElementById('modal-root').appendChild(this.element);
    }

    componentWillUnmount() {
        document.getElementById('modal-root').removeChild(this.element);
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.element
        );
    }
}