import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from './home/Home';
import Contacts from './contacts/Contacts';
import Account from './profile/Account';
import Materials from './materials/Materials';
import NotFound from "./NotFound";
import NotificationContainer from "./notifications/NotificationContainer";

class Main extends React.Component {
    render() {
        return (
            <>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/contacts" element={<Contacts />} />
                    <Route path="/account/*" element={<Account />} />
                    <Route path="/materials/:subject/*" element={<Materials />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <div id="modal-root" className="modal-root"></div>
                <NotificationContainer />
            </>
        );
    }
}

export default Main;