import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import NavMenu from './NavMenu';
import NavbarToggler from "./NavbarToggler";
import "./Navbar.css";

class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isScrolled: this.isScrolled()
        };
    }

    static propTypes = {
        isToggled: PropTypes.bool
    };

    isScrolled = () => {
        const doc = document.documentElement,
            position = (window.scrollY || doc.scrollTop)  - (doc.clientTop || 0);

        return position > 30;
    };

    onScroll = () => {
        const isScrolled = this.isScrolled();

        if (this.state.isScrolled !== isScrolled) {
            this.setState({
                isScrolled: isScrolled
            })
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    render() {
        let classNames = ['navbar', 'navbar-expand-lg', 'navbar-dark', 'navbar-custom', 'fixed-top'];
        if (this.state.isScrolled) {
            classNames.push('top-nav-collapse');
        }

        return (
            <nav className={classNames.join(' ')}>
                {/* Text Logo - Use this if you don't have a graphic logo */}
                <Link className="navbar-brand logo-text" to="/">Підготовка до ЗНО</Link>

                {/* Image Logo */}
                {/*<Link to="/" className="navbar-brand logo-image">
                    <img src="/images/logo.svg" alt="alternative" />
                </Link>*/}

                <NavbarToggler />

                <NavMenu isShown={this.props.isToggled} items={[
                    {text: 'Головна', href: '/'},
                    {text: 'Конспекти', items: [
                        {text: 'Історія', href: '/materials/history/synopsis'},
                        {text: 'Математика', href: '/materials/math/synopsis'},
                        {text: 'Українська мова', href: '/materials/ukrmova/synopsis'},
                    ]},
                    {text: 'Відео лекції', href: '/materials/history/video-lectures'},
                    /*{text: 'Тести', href: '/materials/history/zno-tests'},*/
                    {text: 'Тести', items: [
                        {text: 'Історія', href: '/materials/history/zno-tests'},
                        {text: 'Математика', href: '/materials/math/zno-tests'},
                        {text: 'Українська мова', href: '/materials/ukrmova/zno-tests'},
                    ]},
                    /*{text: 'Як це працює', href: '/how-it-works'},*/
                    {text: 'Контакти', href: '/contacts'}
                ]}/>
            </nav>
        );
    }
}

const mapStateToProps = state => ({
    isToggled: state.navbar.isToggled
});

export default connect(mapStateToProps)(Navbar);