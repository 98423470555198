import React from 'react';

export default class Form extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    getFieldError(fieldName) {
        if (!this.state.errors[fieldName]) {
            return;
        }

        return this.state.errors[fieldName];
    }

    renderError(fieldName) {
        let message = this.getFieldError(fieldName);
        if (!message) {
            return;
        }
        if (typeof message === 'object') {
            message = message.join('<br>');
        }
        if (message.trim() === '') {
            return;
        }

        return <div className="text-danger">{message}</div>
    }

    getFieldClassName(fieldName, classes = []) {
        if (this.getFieldError(fieldName)) {
            classes.push('is-invalid');
        }

        return classes.join(' ');
    }
}