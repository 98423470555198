import React, { useState, useEffect } from 'react';
import {useDispatch} from "react-redux";
import ModalDialog from "../base/modalDialog/ModalDialog";
import Login from "./Login";
import Registration from "./Registration";
import {navbarActions} from "../../reducers/navbar";

const UnauthorizedUserMenu = () => {
    const dispatch = useDispatch();
    const [isLoginModalOpened, setIsLoginModalOpened] = useState(false);
    const [isRegistrationModalOpened, setIsRegistrationModalOpened] = useState(false);

    useEffect(() => {
        if (isLoginModalOpened || isRegistrationModalOpened) {
            dispatch(navbarActions.close());
        }
    }, [isLoginModalOpened, isRegistrationModalOpened, dispatch]);

    const openLoginForm = () => {
        setIsRegistrationModalOpened(true);
        setIsLoginModalOpened(true);
    };

    const closeLoginForm = () => {
        setIsLoginModalOpened(false);
    };

    const openRegistrationForm = () => {
        setIsLoginModalOpened(false);
        setIsRegistrationModalOpened(true);
    };

    const closeRegistrationForm = () => {
        setIsRegistrationModalOpened(false);
    };

    let modal;
    if (isLoginModalOpened) {
        modal = <ModalDialog closeHandler={closeLoginForm} contentClasses={['login-form-wrapper']}>
            <Login />
        </ModalDialog>;
    } else if (isRegistrationModalOpened) {
        modal = <ModalDialog closeHandler={closeRegistrationForm} contentClasses={['registration-form-wrapper']}>
            <Registration />
        </ModalDialog>;
    }

    return (
        <>
            <li className="nav-item">
                <span id="login-btn" className="nav-link link" onClick={openLoginForm}>Вхід</span>
            </li>
            <li className="nav-item">
                <span id="register-btn" className="nav-link link" onClick={openRegistrationForm}>Реєстрація</span>
            </li>
            {modal}
        </>
    );
};

export default UnauthorizedUserMenu;