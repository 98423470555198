import React from 'react';
import PropTypes from 'prop-types';

export default class AnswerVariantLabel extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        isNumeric: PropTypes.bool
    };

    static defaultProps = {
        isNumeric: false
    };

    render() {
        let alphabet = ['А', 'Б', 'В', 'Г', 'Д'],
            label = this.props.index;
        if (!this.props.isNumeric) {
            label = alphabet[label];
        } else {
            ++label;
        }

        return (
            <div className="answer-variant-label">{label}</div>
        );
    }
}