import ErrorMessage from "../../base/errorMessage/ErrorMessage";

const SynopsisError = () => {
    return (
        <ErrorMessage>
            Виникла помилка при завантаженні конспекта. Спробуйте ще раз, якщо помилка повториться повідомте, будь ласка, вчителя
        </ErrorMessage>
    );
};

export default SynopsisError;