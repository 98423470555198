import {combineReducers} from 'redux';
import auth from './auth';
import navbar from './navbar';
import notifications from "./notifications";
import synopses from "./synopses";
import tests from "./tests";
import videoLectures from "./videoLectures";

const rootReducer = () => combineReducers({
    auth,
    navbar,
    notifications,
    synopses,
    tests,
    videoLectures
});

export default rootReducer;