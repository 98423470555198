import React from 'react';
import PropTypes from 'prop-types';

export default class TestResultRatingScore extends React.Component {
    static propTypes = {
        testScore: PropTypes.number.isRequired,
        maxScore: PropTypes.number.isRequired
    };

    convertTestScoreToRatingScore = testScore => {
        const scoreMap = {
            26: 100,
            27: 102,
            28: 105,
            29: 108,
            30: 111,
            31: 114,
            32: 117,
            33: 120,
            34: 122,
            35: 125,
            36: 128,
            37: 130,
            38: 133,
            39: 135,
            40: 138,
            41: 140,
            42: 142,
            43: 144,
            44: 146,
            45: 148,
            46: 150,
            47: 151,
            48: 153,
            49: 154,
            50: 156,
            51: 157,
            52: 159,
            53: 160,
            54: 162,
            55: 163,
            56: 164,
            57: 165,
            58: 167,
            59: 168,
            60: 169,
            61: 170,
            62: 171,
            63: 172,
            64: 173,
            65: 174,
            66: 175,
            67: 176,
            68: 178,
            69: 179,
            70: 180,
            71: 180.5,
            72: 181,
            73: 182,
            74: 183,
            75: 184,
            76: 185,
            77: 186,
            78: 187,
            79: 188,
            80: 189,
            81: 190,
            82: 190.5,
            83: 191,
            84: 192,
            85: 193,
            86: 194,
            87: 195,
            88: 195.5,
            89: 196,
            90: 197,
            91: 198,
            92: 199,
            93: 199.5,
            94: 200
        };

        return scoreMap[testScore];
    }

    render() {
        if (this.props.maxScore !== 94) {
            return null;
        }

        let score = <b className="text-danger">&lt; 100</b>;
        if (this.props.testScore >= 26) {
            let ratingScore = this.convertTestScoreToRatingScore(this.props.testScore);
            if (!ratingScore) {
                return null;
            }
            score = <span><b>{ratingScore}</b>/200</span>;
        }

        return (
            <p>Рейтинговий бал {score}</p>
        );
    }
}