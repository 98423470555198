import React from 'react';
import {connect} from 'react-redux';
import { Navigate } from 'react-router-dom';
import {Routes, Route} from "react-router-dom";
import Settings from './settings/Settings';
import Cabinet from "./cabinet/Cabinet";

class Account extends React.Component {
    render() {
        if (!this.props.loggedIn) {
            return <Navigate to="/" />;
        }

        return (
            <div className="account-page">
                <Routes>
                    <Route exact path={`settings`} element={<Settings />}/>
                    <Route exact path={`cabinet`} element={<Cabinet />}/>
                </Routes>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.loggedIn
});

export default connect(mapStateToProps)(Account);