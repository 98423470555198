import {Component} from 'react';

class PageTitle extends Component {
    constructor(props) {
        super(props);

        this.titleElement = document.getElementsByTagName('title')[0];
    }

    componentDidMount() {
        this.titleElement.innerText = this.props.pageTitle
            ? this.props.pageTitle + ' | ' + this.props.siteName
            : this.props.siteName;
    }

    componentWillUnmount() {
        this.titleElement.innerText = this.props.siteName;
    }

    render() {
        return null;
    }
}

PageTitle.defaultProps = {
    pageTitle: null,
    siteName: 'Підготовка до ЗНО онлайн'
};

export default PageTitle;