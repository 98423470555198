import {createSlice} from "@reduxjs/toolkit";
import znoTestsApi from "../api/znoTests";

const testsSlice = createSlice({
    name: 'tests',
    initialState: {
        history: {
            isLoaded: false,
            error: false,
            categoryList: []
        },
        math: {
            isLoaded: false,
            error: false,
            categoryList: []
        },
        ukrmova: {
            isLoaded: false,
            error: false,
            categoryList: []
        }
    },
    reducers: {
        update(state, action) {
            state[action.payload.subject] = {
                isLoaded: action.payload.isLoaded,
                error: action.payload.error,
                categoryList: action.payload.categoryList
            };
        },
        setError(state, action) {
            state[action.payload.subject].error = action.payload.error;
        }
    }
});

export const testsActions = testsSlice.actions;

export const fetchTests = (subject) => {
    return (dispatch) => {
        dispatch(testsActions.setError({
            subject: subject,
            error: false
        }));
        znoTestsApi.getCategoryList(subject).then(response => {
            dispatch(testsActions.update({
                subject: subject,
                isLoaded: true,
                error: response.data.length === 0,
                categoryList: response.data
            }));
        }).catch(e => {
            dispatch(testsActions.update({
                subject: subject,
                isLoaded: false,
                error: true,
                categoryList: []
            }));
        });
    };
};

export default testsSlice.reducer;