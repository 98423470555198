import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {MathJaxContext} from "better-react-mathjax";
import BackToTop from "./base/backToTop/BackToTop";
import Navbar from './navbar/Navbar';
import Main from './Main';
import Footer from "./Footer";
import Copyright from "./Copyright";
import GA from "./analytics/GA";
import FBPixel from "./analytics/FBPixel";
import { GA_TRACKING_ID, FB_PIXEL_ID } from "../constants/analytics";
import './App.css';

const App = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <MathJaxContext
            src="/js/mathjax/tex-mml-svg.js"
            config={{
                loader: {load: ['[tex]/html']},
                tex: {packages: {'[+]': ['html']}}
            }}
        >
            <BackToTop />
            <div className="page-wrapper wrapper">
                <Navbar/>
                <Main/>
            </div>
            <Footer />
            <Copyright />
            {GA_TRACKING_ID && <GA id={GA_TRACKING_ID} location={location.pathname} />}
            {FB_PIXEL_ID && <FBPixel id={FB_PIXEL_ID} />}
        </MathJaxContext>
    );
}

export default App;