import React from 'react';
import Modal from "../../base/modal/Modal";
import {Link} from "react-router-dom";
import {BASE_API_URL} from "../../../constants/api";
import VideoPlayer from "./VideoPlayer";
import ModalPreloader from "../../base/ModalPreloader";
import tokenApi from "../../../api/token";

export default class VideoLectureView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            jwt: null
        };
    }

    componentDidMount() {
        this.fetchToken();
    }

    fetchToken = () => {
        if (this.state.isLoaded) {
            this.setState({isLoaded: false})
        }

        tokenApi.getToken().then(response => {
            this.setState({
                isLoaded: true,
                jwt: response.data.token
            });
        });
    }

    getVideoUrl = () => {
        return BASE_API_URL + '/history/video-lectures/view?id=' + this.props.id + '&token=' + encodeURIComponent(this.state.jwt);
    }

    render() {
        if (!this.state.isLoaded) {
            return <ModalPreloader />;
        }

        return (
            <Modal wrapperClasses={['video-lecture-view']} contentClasses={['video-lecture-content']}>
                <span className="close-modal-btn"><Link to="/materials/history/video-lectures">&times;</Link></span>
                <VideoPlayer src={this.getVideoUrl()} />
            </Modal>
        );
    }
}