import React from "react";
import PropTypes from 'prop-types';
import SocialIcon from "../base/SocialIcon";

export default class TeamMember extends React.Component {
    render() {
        let {photoSrc, name, socialLinks} = this.props;
        socialLinks = socialLinks.map(item => <SocialIcon key={item.url} {...item} />);
        return (
            <div className="team-member">
                <div className="image-wrapper">
                    <img className="img-fluid" src={photoSrc} alt={name} />
                </div>
                <p className="p-large"><strong>{name}</strong></p>
                {this.props.job && <p className="job-title">{this.props.job}</p>}
                <span className="social-icons">{socialLinks}</span>
            </div>
        );
    }
}

TeamMember.propTypes = {
    photoSrc: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
    socialLinks: PropTypes.array
};