import api from './abstract';

const synopsisApi = {
    getSynopsisList(subject) {
        return api.get('/' + subject + '/synopsis/list');
    },

    getSynopsis(subject, id) {
        return api.get('/' + subject + '/synopsis/get-synopsis/' + id);
    },
};

export default synopsisApi;