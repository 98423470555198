import React from "react";

export default class TestResultTimestamp extends React.Component {
    render() {
        let hours = '0' + this.props.time.getHours(),
            minutes = '0' + this.props.time.getMinutes(),
            day = '0' + this.props.time.getDate(),
            month = '0' + (this.props.time.getMonth() + 1),
            year = this.props.time.getFullYear();

        return (
            <p className="timestamp">
                {hours.slice(-2)}:{minutes.slice(-2)} {day.slice(-2)}.{month.slice(-2)}.{year}
            </p>
        );
    }
}