import {createSlice} from "@reduxjs/toolkit";
import synopsisApi from "../api/synopsis";

const synopsesSlice = createSlice({
    name: 'synopses',
    initialState: {
        history: {
            isLoaded: false,
            error: false,
            categoryList: []
        },
        math: {
            isLoaded: false,
            error: false,
            synopsisList: []
        },
        ukrmova: {
            isLoaded: false,
            error: false,
            synopsisList: []
        }
    },
    reducers: {
        update(state, action) {
            state[action.payload.subject] = {
                isLoaded: action.payload.isLoaded,
                error: action.payload.error,
                synopsisList: action.payload.synopsisList
            };
        },
        setError(state, action) {
            state[action.payload.subject].error = action.payload.error;
        }
    }
});

export const fetchSynopses = (subject) => {
    return (dispatch) => {
        dispatch(synopsesSlice.actions.setError({
            subject: subject,
            error: false
        }));
        synopsisApi.getSynopsisList(subject).then(response => {
            dispatch(synopsesSlice.actions.update({
                subject: subject,
                isLoaded: true,
                error: response.data.length === 0,
                synopsisList: response.data
            }));
        }).catch(e => {
            dispatch(synopsesSlice.actions.update({
                subject: subject,
                isLoaded: false,
                error: true,
                synopsisList: []
            }));
        });
    };
};

export default synopsesSlice.reducer;