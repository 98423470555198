import React, {Suspense, useState, useEffect} from "react";
import Preloader from "../../base/preloader/Preloader";
import SynopsisError from "./SynopsisError";
import synopsisApi from "../../../api/synopsis";

const Viewer = React.lazy(() => import("../../pdf/Viewer"));

const SynopsisView = ({id, subject}) => {
    const [fileUrl, setFileUrl] = useState(null);
    const [couldNotFetch, setCouldNotFetch] = useState(false);

    useEffect(() => {
        setFileUrl(null);
        setCouldNotFetch(false);
        synopsisApi.getSynopsis(subject, id)
            .then(response => {
                let fileData = response.data.file;
                // Cut the prefix `data:application/pdf;base64` from the raw base 64
                if (fileData.indexOf('data:application/pdf;base64,') === 0) {
                    fileData = fileData.substring('data:application/pdf;base64,'.length);
                }

                const bytes = atob(fileData);
                let length = bytes.length,
                    out = new Uint8Array(length);

                while (length--) {
                    out[length] = bytes.charCodeAt(length);
                }

                let data = new Blob([out], { type: 'application/pdf' });

                setFileUrl(URL.createObjectURL(data));
            }).catch(e => {
                setCouldNotFetch(true);
            });
    }, [id, subject, setCouldNotFetch]);

    if (couldNotFetch) {
        return <SynopsisError />;
    }

    const preloader = <div className="synopsis-preloader-wrapper">
        <Preloader color="primary" />
    </div>;

    if (fileUrl === null) {
        return preloader;
    }

    return <Suspense fallback={preloader}>
        <Viewer fileUrl={fileUrl} viewerClassName="synopsis-view" />
    </Suspense>;
};

export default SynopsisView;