import {MathJax} from "better-react-mathjax";
import {SUBJECT_MATH} from "../../../../constants/subject";

const TextBlock = ({subject, children}) => {
    if (subject === SUBJECT_MATH) {
        return <MathJax inline={true} dynamic={true}>
            {children}
        </MathJax>;
    }

    return children;
};

export default TextBlock;