import React from 'react';
import {NEXT_ZNO_DATE} from "../../constants/settings";

export default class DaysLeft extends React.Component {
    render() {
        const znoDate = new Date(NEXT_ZNO_DATE);
        const now = new Date();
        let diff = Math.floor((znoDate.getTime() - now.getTime()) / 86400000);
        if (diff <= 0) {
            return null
        }

        let daysText = '';

        if (diff > 10 && diff < 20) {
            daysText = 'днів';
        } else {
            switch (diff % 10) {
                case 1:
                    daysText = 'день';
                    break;
                case 2:
                case 3:
                case 4:
                    daysText = 'дні';
                    break;
                default:
                    daysText = 'днів';
                    break;
            }
        }

        return (
            <p className="p-heading p-large">
                До тестування залишилось <span className="days-left">{diff}</span> {daysText}
            </p>
        );
    }
}