import React from 'react';
import ListElement from "../../../base/list/ListElement";
import ListElementWrapper from "../../../base/list/ListElementWrapper";

export default class QuestionList extends React.Component {
    render() {
        return (
            <ul className="question-list list">
                {this.props.list.map((question, index) => {
                    const classes = [];
                    if (index === this.props.currentQuestionNumber) {
                        classes.push('current');
                    }
                    if (this.props.answeredQuestions.includes(question.id + '')) {
                        classes.push('is-answered');
                    }
                    return <ListElement key={question.id} classNames={classes} onClick={() => this.props.setCurrentQuestion(index)}>
                        <ListElementWrapper>{index + 1}</ListElementWrapper>
                    </ListElement>;
                })}
            </ul>
        );
    }
}