import React from 'react';
import PropTypes from 'prop-types';

export default class TestimonialItem extends React.Component {
    render() {
        const {imgSrc, text, author} = this.props;
        return (
            <div className="swiper-slide">
                <div className="card">
                    <img className="card-image" src={imgSrc} alt={author} />
                    <div className="card-body">
                        <p className="testimonial-text">{text}</p>
                        {author ? <p className="testimonial-author">{author}</p> : null}
                    </div>
                </div>
            </div>
        );
    }
}

TestimonialItem.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    author: PropTypes.string
};